import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, LinearProgress, MenuItem, TextField } from "@material-ui/core";
import { Order } from "../../../model/Order";
import React, { useEffect, useState } from "react";
import { ApiBackend } from "../../../providers/apibackend";
import { raceDataProductTypes } from "../../../model/Product";
import { Alert } from "@material-ui/lab";
import withAlert, { IAlertContext } from "../../Common/dialog/Alert";
import { cloneDeep } from "lodash";

interface Props {
    orderId: string;
    orderItemId: string;
    onAbort: () => void;
    onComplete: () => void;
}

type ItemSelection = {
    source: number;
    target: number;
}

const ManageOrderSplitItemDialog = ({ orderId, orderItemId, onAbort, onComplete, showAlert }: Props & IAlertContext) => {
    const [order, setOrder] = useState<Order>(null as Order);
    const [splittable, setSplittable] = useState(false);
    const [maxNumItems, setMaxNumItems] = useState(1);
    const [itemSelection, setItemSelection] = useState<ItemSelection>({ source: 0, target: 0 });
    const [initialized, setInitialized] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    const backend = new ApiBackend();

    useEffect(() => {

        const init = async () => {
            const sourceOrder = await backend.retreiveOrder(orderId);
            const sourceOrderItem = sourceOrder.items.find(x => x.Id === orderItemId);
            setOrder(sourceOrder);

            const isRaceProduct = raceDataProductTypes.some(x => x === sourceOrderItem.product?.type);
            const canSplit = !isRaceProduct && sourceOrderItem.numberOfProducts > 1;
            setSplittable(canSplit);
            setMaxNumItems(sourceOrderItem.numberOfProducts);
            setItemSelection({ source: sourceOrderItem.numberOfProducts, target: 0 });
            setInitialized(true);
        };

        init();
    }, []);

    const numItemsOptionValues = (): number[] => {
        const items = [];
        for (let i = 0; i < maxNumItems; i++) {
            items.push(i + 1);
        }

        return items;
    };

    const handleSourceChange = (value: string) => {
        const nextValue = {
            source: +value,
            target: maxNumItems - +value
        };
        setItemSelection(nextValue);
    };

    const renderContent = () => {
        if (!initialized) {
            return <LinearProgress color="secondary" />;
        }

        if (!splittable) {
            return (
                <Alert severity="info">
                    Denna orderrad är inte möjlig att dela upp
                </Alert>
            );
        }

        return (
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <TextField
                        select
                        style={{ flex: 1, margin: '0 3px' }}
                        label="Rad 1"
                        InputLabelProps={{ shrink: true }}
                        value={itemSelection.source ?? ""}
                        fullWidth
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            handleSourceChange(e.target.value);
                        }}
                    >
                        {numItemsOptionValues().map((item, idx) => {
                            return <MenuItem key={idx} value={item}>{item}</MenuItem>;
                        })}
                    </TextField>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        disabled

                        style={{ flex: 1, margin: '0 3px' }}
                        label="Rad 2"
                        InputLabelProps={{ shrink: true }}
                        value={itemSelection.target ?? ""}
                        fullWidth
                    >
                    </TextField>
                </Grid>
            </Grid>
        )
    };

    const saveDisabled = () => {
        if (!initialized || isSaving || !splittable) {
            return true;
        }

        return itemSelection.source === 0 || itemSelection.target === 0;
    };

    const handleSave = async () => {
        setIsSaving(true);
        try {

            const nextOrder = cloneDeep(order);
            const sourceOrderItem = nextOrder.items.find(x => x.Id === orderItemId);
            const targetOrderItem = cloneDeep(sourceOrderItem);

            sourceOrderItem.numberOfProducts = itemSelection.source;
            targetOrderItem.numberOfProducts = itemSelection.target;
            nextOrder.items.push(targetOrderItem);

            for (let i = 0; i < nextOrder.items.length; i++) {
                const item = nextOrder.items[i];
                item.Id = null;
            }

            await backend.updateOrderItems(nextOrder.id, nextOrder.items);

            onComplete();
        } catch {
            showAlert("Fel", "Någonting gick fel när vi försökte dela upp orderraden");
        } finally {
            setIsSaving(false);
        }
    };

    const render = () => {
        const btnDisabled = !initialized || isSaving;

        return <Dialog
            disableEscapeKeyDown
            maxWidth="sm"
            aria-labelledby="split-orderrow-dialog-title"
            fullWidth={true}
            open={true}>
            <DialogTitle id="split-orderrow-dialog-title">
                Dela upp orderrad
            </DialogTitle>
            <DialogContent dividers>
                {renderContent()}
            </DialogContent>
            <DialogActions>
                <Box style={{ display: "flex", width: "100%", height: "40px" }}>
                    {isSaving &&
                        <Box style={{ width: 200 }}>
                            <CircularProgress color="secondary" />
                        </Box>
                    }
                    <Box style={{ display: "flex", flex: 1, gap: 8, justifyContent: "flex-end" }}>
                        <Button
                            variant="contained"
                            disabled={btnDisabled}
                            onClick={() => {
                                onAbort();
                            }}>
                            Avbryt
                        </Button>
                        <Button
                            variant="contained"
                            disabled={saveDisabled()}
                            onClick={() => {
                                handleSave();
                            }}>
                            Spara
                        </Button>
                    </Box>
                </Box>
            </DialogActions>
        </Dialog>;
    };

    return render();
};

export default withAlert(ManageOrderSplitItemDialog);
