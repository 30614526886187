import { Button, ButtonGroup, Checkbox, createStyles, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, Theme, withStyles, WithStyles } from '@material-ui/core';
import React, { Fragment } from 'react';
import { Order } from '../../../model/Order';
import OrderRowMetadata from '../OrderRowMetadata';
import OrderRowEntry from '../OrderRowEntry';
import ManageOrderEditItemsRepay from './ManageOrderEditItemsRepay';
import { ApiBackend } from '../../../providers/apibackend';
import { EntryAddonProduct } from '../../../model/Entry';
import { OrderItem } from '../../../model/OrderItem';
import ManageOrderEditItemsChangeItem from './MangeOrderEditItemsChangeItem';
import { cloneDeep } from 'lodash';
import ManageOrderRebookItem from './ManageOrderRebookItem';
import ManageOrderAddItem from './ManageOrderAddItem';
import { RebookOrderItemRequest } from '../../../providers/models';
import ManageOrderRebookEnervitItem from './ManageOrderTransferEnervit';
import { raceDataProductTypes } from '../../../model/Product';
import ManageOrderSplitItemDialog from './ManageOrderSplitItemDialog';

interface Props {
    order: Order;
    onSaved: () => void;
}

interface State {
    order: Order;
    saving: boolean;
    checked: any;
    repayOrder: boolean;
    repayReason: string;
    editItem: OrderItem;
    editItemNewRow: OrderItem;
    rebookItem: OrderItem;
    rebookEnervitItem: OrderItem;
    rebookNewOrder: Order;
    handleRebookDiffWith: string;
    handleRebookDiffWithAccount: string;
    handleRebookDiffAccountComment: string;
    rebookFormIsValid: boolean;
    addOrderItem: boolean;
    sendOrderEmail: boolean;
    addOrderItemItem: OrderItem;
    splitItem: OrderItem;
}

class ManageOrderEditItems extends React.Component<Props & WithStyles, State> {
    constructor(props: Props & WithStyles) {
        super(props);

        this.state = {
            order: this.props.order,
            saving: false,
            checked: {},
            repayOrder: false,
            repayReason: '',
            editItem: null,
            editItemNewRow: null,
            rebookItem: null,
            rebookEnervitItem: null,
            rebookNewOrder: null,
            handleRebookDiffWith: null,
            addOrderItem: false,
            addOrderItemItem: null,
            sendOrderEmail: true,
            handleRebookDiffWithAccount: null,
            handleRebookDiffAccountComment: null,
            rebookFormIsValid: false,
            splitItem: null
        };
    }

    render() {
        const o = this.state.order;

        const someEnervit = () => {
            return o.items.some(x => x.productName?.indexOf("Enervit") >= 0);
        };

        const numChecked = (): number => {
            let num = 0;
            for (let idx = 0; idx < o.items.length; idx++) {
                if (this.state.checked[idx]) {
                    num++;
                }
            }

            return num;
        };

        const isRebookActive = () => {
            if (o.Status !== "Completed") {
                return false;
            }

            return numChecked() === 1;
        };

        const isRebookEnervitActive = () => {
            if (!someEnervit()) {
                return false;
            }

            if (o.Status !== "Completed") {
                return false;
            }

            if (numChecked() !== 1) {
                return false;
            }

            let oi: OrderItem;
            for (let i = 0; i < o.items.length; i++) {
                if (this.state.checked[i]) {
                    oi = o.items[i];
                    break;
                }
            }

            return oi.productName?.indexOf("Enervit") >= 0;
        };

        const isRepayActive = () => {
            return numChecked() > 0;
        };

        const isChangeActive = () => {
            return numChecked() === 1;
        };

        const isSplitActive = () => {
            if (o.Status !== "Completed") {
                return false;
            }

            if (numChecked() !== 1) {
                return false;
            }

            let oi: OrderItem;
            for (let i = 0; i < o.items.length; i++) {
                if (this.state.checked[i]) {
                    oi = o.items[i];
                    break;
                }
            }

            const isRaceProduct = raceDataProductTypes.some(x => x === oi.product?.type);

            return !isRaceProduct && oi.numberOfProducts > 1;
        };

        return <Grid container spacing={2}>
            <Grid item xs={1}></Grid>
            <Grid item xs={1}><b>Antal</b></Grid>
            <Grid item xs={2}><b>Produkt</b></Grid>
            <Grid item xs={2}><b>Variant</b></Grid>
            <Grid item xs={2}><b>Prisgrupp</b></Grid>
            <Grid item xs={2}><b>Tillfälle</b></Grid>
            <Grid item xs={1}><b>à pris</b></Grid>
            <Grid item xs={1}><b>Delsumma</b></Grid>
            {o.items.map((orderItem, idx) => {
                const s = { textDecoration: orderItem.reverted ? "line-through" : "" };
                return <Grid key={idx} item xs={12} style={{ border: "1px solid #ccc", marginTop: 5, backgroundColor: "#eee" }}>
                    <Grid container>
                        <Grid item xs={1}>
                            {!orderItem.reverted && (
                                <Checkbox style={{ padding: 0, margin: 0 }} key={idx} color="primary"
                                    checked={this.state.checked[idx] ? true : false}
                                    onChange={(e) => {
                                        let ch = this.state.checked;
                                        ch[idx] = e.target.checked;
                                        this.setState({ checked: ch });
                                    }}
                                />
                            )}
                        </Grid>
                        <Grid item style={s} xs={1}>{orderItem.numberOfProducts} st</Grid>
                        <Grid item style={s} xs={2}>{orderItem.productName}</Grid>
                        <Grid item style={s} xs={2}>{orderItem.variantName}</Grid>
                        <Grid item style={s} xs={2}>{orderItem.priceGroupName}</Grid>
                        <Grid item style={s} xs={2}>{orderItem.occationName}</Grid>
                        <Grid item style={s} xs={1}>{orderItem.itemPrice} kr</Grid>
                        <Grid item style={s} xs={1}>{orderItem.itemPrice * orderItem.numberOfProducts} kr</Grid>
                        {(orderItem.metadata || orderItem.occationName) &&
                            <OrderRowMetadata
                                rowStyle={s}
                                editMode={true}
                                item={orderItem}
                                onUpdateMetadata={async (metadata) => {
                                    orderItem.metadata = metadata;
                                }} />
                        }
                        {(orderItem.entry) &&
                            <OrderRowEntry
                                rowStyle={s}
                                editMode={true}
                                item={orderItem}
                                onConvertEntryAddonToOwnItem={(toRemoveEntryAddon: EntryAddonProduct, newOrderItem: OrderItem) => {
                                    let o = this.state.order;
                                    o.items.push(newOrderItem);
                                    orderItem.entry.addOns = orderItem.entry.addOns.filter(x => x != toRemoveEntryAddon);
                                }}
                                onChange={() => {
                                    this.props.onSaved();
                                }}
                            />
                        }
                    </Grid>
                </Grid>
            })}
            {o.discountCodes && o.discountCodes.map((code, idx) => {
                return <Fragment key={idx}>
                    <Grid item xs={3}>{1}</Grid>
                    <Grid item xs={5}>{code.code} ({code.description})</Grid>
                    <Grid item xs={2}>-{code.value} kr</Grid>
                    <Grid item xs={2}>-{code.value} kr</Grid>
                </Fragment>
            })}

            <Grid item xs={12}>
                <Divider />
            </Grid>

            <Grid item xs={10}>
                <ButtonGroup color="secondary">
                    <Button
                        disabled={!isChangeActive()}
                        onClick={() => {
                            for (let i = 0; i < o.items.length; i++) {
                                if (this.state.checked[i]) {
                                    const clone = cloneDeep(o.items[i]);
                                    clone.Id = null;
                                    this.setState({ editItem: o.items[i], editItemNewRow: clone });
                                    break;
                                }
                            }
                        }}
                    >
                        Ändra
                    </Button>
                    <Button
                        disabled={!isRebookActive()}
                        onClick={() => {
                            for (let i = 0; i < o.items.length; i++) {
                                if (this.state.checked[i]) {
                                    this.setState({ rebookItem: o.items[i] });
                                    break;
                                }
                            }
                        }}
                    >
                        Omboka
                    </Button>
                    {someEnervit() &&
                        <Button
                            disabled={!isRebookEnervitActive()}
                            onClick={() => {
                                for (let i = 0; i < o.items.length; i++) {
                                    if (this.state.checked[i]) {
                                        this.setState({ rebookEnervitItem: o.items[i] });
                                        break;
                                    }
                                }
                            }}
                        >
                            Flytta Enervit
                        </Button>
                    }
                    <Button
                        disabled={!isRepayActive()}
                        onClick={() => {
                            this.setState({ repayOrder: true });
                        }}
                    >
                        Återbetala
                    </Button>
                    <Button
                        disabled={numChecked() > 0}
                        onClick={() => {
                            this.setState({ addOrderItem: true });
                        }}
                    >
                        Lägg till orderrad
                    </Button>
                    <Button
                        disabled={!isSplitActive()}
                        onClick={() => {
                            for (let i = 0; i < o.items.length; i++) {
                                if (this.state.checked[i]) {
                                    this.setState({ splitItem: o.items[i] });
                                    break;
                                }
                            }
                        }}
                    >
                        Dela upp
                    </Button>
                </ButtonGroup>
            </Grid>
            <Grid item xs={2}>Totalt: <b>{o.totalSum} kr</b></Grid>
            {this.state && this.state.repayOrder && this.renderAddRepayData()}
            {this.state && this.state.editItem && this.renderEditItem()}
            {this.state && this.state.rebookItem && this.renderRebookItem()}
            {this.state && this.state.rebookEnervitItem &&
                <ManageOrderRebookEnervitItem
                    sourceOrderId={this.state.order.id}
                    sourceItemId={this.state.rebookEnervitItem.Id}
                    onAbort={() => {
                        this.setState({ rebookEnervitItem: null, rebookNewOrder: null });
                    }}
                    onTransferred={() => {
                        this.setState({ rebookEnervitItem: null, rebookNewOrder: null })
                        this.props.onSaved();
                    }}
                />
            }
            {this.state && this.state.addOrderItem && this.renderAddOrderItem()}
            {this.state && this.state.splitItem &&
                <ManageOrderSplitItemDialog
                    orderId={o.id}
                    orderItemId={this.state.splitItem.Id}
                    onAbort={() => {
                        this.setState({ splitItem: null });
                    }}
                    onComplete={() => {
                        this.setState({ splitItem: null }, () => {
                            this.props.onSaved();
                        });
                    }}
                />
            }
        </Grid>;
    }

    async handleRepay() {
        this.setState({ saving: true });
        const order = this.props.order;
        let back = new ApiBackend();

        let toRevert = [];
        for (let idx = 0; idx < order.items.length; idx++) {
            if (this.state.checked[idx]) {
                toRevert.push(order.items[idx]);
            }
        }

        await back.revertOrderItems(order.id, toRevert, ApiBackend.getCurrentUserName(), this.state.repayReason);
        this.setState({ saving: false });
        this.props.onSaved();
    }

    async handleRebook() {
        this.setState({ saving: true });
        let back = new ApiBackend();
        this.state.rebookNewOrder.contact = this.props.order.contact;
        this.state.rebookNewOrder.locale = this.props.order.locale;
        let createdOrder = await back.adminCreateOrder(this.state.rebookNewOrder, !this.state.sendOrderEmail);
        if (createdOrder) {
            const oldOrder = this.props.order;
            let rebookedOrderItem = null;
            for (let idx = 0; idx < oldOrder.items.length; idx++) {
                if (this.state.checked[idx]) {
                    rebookedOrderItem = oldOrder.items[idx];
                    break;
                }
            }

            if (rebookedOrderItem) {
                const request: RebookOrderItemRequest = {
                    orderItemId: rebookedOrderItem.Id,
                    rebookedBy: ApiBackend.getCurrentUserName(),
                    rebookedReason: `Ombokad till ${createdOrder.publicId}: ${this.state.repayReason}`,
                    newOrderId: createdOrder.id,
                    handleDiffWith: this.state.handleRebookDiffWith,
                    handleDiffWithAccount: this.state.handleRebookDiffWithAccount,
                    handleDiffWithAccountComment: this.state.handleRebookDiffAccountComment,
                    suppressEmailToCustomer: !this.state.sendOrderEmail
                };
                await back.rebookOrderItems(oldOrder.id, request);
            }
        }

        this.setState({ saving: false });

        this.props.onSaved();
    }

    async handleEditSave() {
        this.setState({ saving: true });
        const order = this.props.order;
        this.state.editItem.reverted = true;
        this.state.editItem.revertedBy = ApiBackend.getCurrentUserName();
        this.state.editItem.revertedWhen = new Date();
        this.state.editItem.revertedReason = this.state.repayReason;

        //move entry to new orderitem
        this.state.editItemNewRow.entry = this.state.editItem.entry;
        this.state.editItem.entry = null;
        order.items.push(this.state.editItemNewRow);

        let back = new ApiBackend();
        await back.updateOrderItems(order.id, order.items);
        this.setState({ saving: false });
        this.props.onSaved();
    }

    renderAddRepayData() {
        return <Dialog
            disableEscapeKeyDown
            maxWidth="lg"
            aria-labelledby="confirmation-dialog-title"
            open={true}
        >
            <DialogTitle id="confirmation-dialog-title">
                Begär återbetalning
            </DialogTitle>
            <DialogContent dividers>
                <ManageOrderEditItemsRepay onTextChanged={(txt) => {
                    this.setState({ repayReason: txt });
                }}
                    text={this.state.repayReason} />
            </DialogContent>
            <DialogActions>
                <Button disabled={!this.state.repayReason || this.state.saving} variant="contained" onClick={() => { this.handleRepay() }}>Spara</Button>
                <Button variant="contained" style={{ marginLeft: 5 }} onClick={() => { this.setState({ repayOrder: false }) }}>Avbryt</Button>
            </DialogActions>
        </Dialog>;
    }

    renderAddOrderItem() {
        return <Dialog
            disableEscapeKeyDown
            maxWidth="lg"
            aria-labelledby="confirmation-dialog-title"
            open={true}
        >
            <DialogTitle id="confirmation-dialog-title">
                Lägg till orderrad
            </DialogTitle>
            <DialogContent dividers>
                <ManageOrderAddItem selectionChanged={(product, variant, priceGroup, occation, numberOfProducts) => {
                    let oi = this.state.addOrderItemItem;
                    if (!oi) {
                        oi = new OrderItem();
                        oi.numberOfProducts = 1;
                    }

                    oi.product = product;
                    oi.productId = product ? product.id : null;
                    oi.productVariantId = variant;
                    oi.productVariantPriceGroupId = priceGroup;
                    oi.productVariantOccationId = occation;
                    oi.numberOfProducts = numberOfProducts;

                    if (product) {
                        oi.productName = product.name;
                        const variantInstance = product.variants.find(x => x.Id == variant);
                        if (variantInstance) {
                            oi.variantName = variantInstance.Name;
                            const priceGroupInstance = variantInstance.priceGroups.find(x => x.id == priceGroup);
                            if (priceGroupInstance) {
                                oi.priceGroupName = priceGroupInstance.Name;
                                oi.itemPrice = priceGroupInstance.price;
                            }

                            if (variantInstance.occations && occation) {
                                const occationInstance = variantInstance.occations.find(x => x.id == occation);
                                if (occationInstance) {
                                    oi.occationName = occationInstance.name;
                                }
                            }
                        }
                    }

                    this.setState({ addOrderItemItem: oi });

                }} />
            </DialogContent>
            <DialogActions>
                <Button disabled={this.state.saving || !this.isAddOrderItemComplete()} variant="contained" onClick={async () => {
                    let back = new ApiBackend();
                    let neworder = await back.addOrderItems(this.state.order.id, [this.state.addOrderItemItem]);
                    this.setState({ order: neworder, addOrderItem: false });
                }}>Spara</Button>
                <Button variant="contained" style={{ marginLeft: 5 }} onClick={() => { this.setState({ addOrderItem: false }) }}>Avbryt</Button>
            </DialogActions>
        </Dialog>;
    }

    isAddOrderItemComplete() {
        const hasOccations = () => {
            if (!this.state.addOrderItemItem.productVariantId) {
                return false;
            }
            const variant = this.state.addOrderItemItem.product.variants.find(x => x.Id == this.state.addOrderItemItem.productVariantId);
            if (!variant) {
                return false;
            }

            return variant.occations && variant.occations.length > 0;
        };

        return this.state.addOrderItemItem && this.state.addOrderItemItem.product && this.state.addOrderItemItem.productId
            && this.state.addOrderItemItem.productVariantId && this.state.addOrderItemItem.productVariantPriceGroupId &&
            (!hasOccations() || this.state.addOrderItemItem.productVariantOccationId);
    }

    renderRebookItem() {
        const formIsDisabled = !this.state.repayReason || !this.state.rebookFormIsValid || this.state.saving;

        return <Dialog
            disableEscapeKeyDown
            maxWidth="lg"
            aria-labelledby="confirmation-dialog-title"
            open={true}>
            <DialogTitle id="confirmation-dialog-title">
                Omboka
            </DialogTitle>
            <DialogContent dividers>
                <ManageOrderRebookItem
                    sourceOrderId={this.state.order.id}
                    item={this.state.rebookItem}
                    onTextChanged={(txt) => {
                        this.setState({ repayReason: txt });
                    }}
                    text={this.state.repayReason}
                    onOrderChanged={(order, formIsValid) => {
                        this.setState({ rebookNewOrder: order, rebookFormIsValid: formIsValid });
                    }}
                    onSetHandleDiffWith={(handleDiffWith: string, handleRebookDiffWithAccount: string, handleRebookDiffAccountComment: string, formIsValid: boolean) => {
                        this.setState({ handleRebookDiffWith: handleDiffWith, handleRebookDiffWithAccount: handleRebookDiffWithAccount, handleRebookDiffAccountComment: handleRebookDiffAccountComment, rebookFormIsValid: formIsValid });
                    }}
                    onSendOrderEmailChanged={(sendemail) => {
                        this.setState({ sendOrderEmail: sendemail });
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button disabled={formIsDisabled} variant="contained" onClick={() => { this.handleRebook() }}>Spara</Button>
                <Button variant="contained" style={{ marginLeft: 5 }} onClick={() => { this.setState({ rebookItem: null, rebookNewOrder: null }) }}>Avbryt</Button>
            </DialogActions>
        </Dialog>;
    }

    renderEditItem() {
        return <Dialog
            disableEscapeKeyDown
            maxWidth="lg"
            aria-labelledby="confirmation-dialog-title"
            open={true}
        >
            <DialogTitle id="confirmation-dialog-title">
                Ändra orderrad
            </DialogTitle>
            <DialogContent dividers>
                <ManageOrderEditItemsChangeItem
                    item={this.state.editItemNewRow}
                    onVariantChanged={(id) => {
                        let it = this.state.editItemNewRow;
                        const variant = this.state.editItemNewRow.product.variants.find(x => x.Id == id);
                        it.productVariantId = id;
                        it.variantName = variant?.Name;
                        it.priceGroupName = variant?.priceGroups[0].Name;
                        it.productVariantPriceGroupId = variant?.priceGroups[0].id;
                        this.setState({ editItemNewRow: it });
                    }}
                    onPriceGroupChanged={(id) => {
                        let it = this.state.editItemNewRow;
                        const variant = this.state.editItemNewRow.product.variants.find(x => x.Id == it.productVariantId);
                        const pg = variant.priceGroups.find(x => x.id == id);
                        it.productVariantPriceGroupId = id;
                        it.priceGroupName = pg?.Name;
                        this.setState({ editItemNewRow: it });
                    }}
                    onOccationChanged={(id) => {
                        let it = this.state.editItemNewRow;
                        const variant = this.state.editItemNewRow.product.variants.find(x => x.Id == it.productVariantId);
                        const occ = variant.occations.find(x => x.id == id);
                        it.productVariantOccationId = id;
                        it.occationName = occ?.name;
                        this.setState({ editItemNewRow: it });
                    }}
                    onNumberOfProductsChanged={(nextValue: string) => {
                        let it = this.state.editItemNewRow;
                        it.numberOfProducts = +nextValue;
                        this.setState({ editItemNewRow: it });
                    }}
                    onTextChanged={(txt) => {
                        this.setState({ repayReason: txt });
                    }}
                    text={this.state.repayReason} />
            </DialogContent>
            <DialogActions>
                <Button disabled={!this.state.repayReason || this.state.saving} variant="contained" onClick={() => { this.handleEditSave() }}>Spara</Button>
                <Button variant="contained" style={{ marginLeft: 5 }} onClick={() => { this.setState({ editItem: null }) }}>Avbryt</Button>
            </DialogActions>
        </Dialog>;
    }
}

const useStyles = ({ palette, spacing }: Theme) => createStyles({
    cardHeader: {
        background: palette.secondary.main,
        color: palette.secondary.contrastText,
        padding: 3
    },
    form: {
        '& > *': {
            margin: spacing(1),
            width: '25ch',
        },
        '& label.Mui-focused': {
            color: palette.secondary.main,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: palette.secondary.main,
        },
    }
});

export default withStyles(useStyles)(ManageOrderEditItems);
