import { Box, TableCell, TableFooter, TableRow } from "@material-ui/core";
import React from "react";
import { IColumnDefinition } from "./models";

export interface EnhancedTableFooterProps<T> {
    columnDefinitions: IColumnDefinition<T>[];
    data: T[];
    selectable: boolean;
}

const EnhancedTableFooter = <T extends unknown>(props: EnhancedTableFooterProps<T>) => {
    const { columnDefinitions, data, selectable } = props;

    const key = (def: IColumnDefinition<T>, idx: number): string | number => {
        if (def.propName) {
            return `${def.propName as string}_${idx}`;
        }

        return idx;
    };

    const renderCell = (columnDefinition: IColumnDefinition<T>, idx: number): JSX.Element => {
        const value = columnDefinition.summarize ? columnDefinition.summarize(data) : null;

        return (
            <TableCell
                key={key(columnDefinition, idx)}
                align="left"
            >
                <Box component="span" style={{ whiteSpace: "nowrap" }}>
                    {value}
                </Box>
            </TableCell>
        );
    };

    return (
        <TableFooter>
            <TableRow>
                {selectable &&
                    <TableCell>
                    </TableCell>
                }
                {columnDefinitions.map((columnDefinition, idx) => (
                    renderCell(columnDefinition, idx)
                ))}
            </TableRow>
        </TableFooter>
    );
}

export default EnhancedTableFooter;
