import React, { Fragment, useEffect, useRef, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Button, Card, CardActions, CardContent, CardHeader, Grid, LinearProgress, Theme, Typography, WithStyles, createStyles, withStyles } from "@material-ui/core";
import RefreshIcon from "@material-ui/icons/Refresh";
import { IInvoice, IInvoiceB2bCustomProduct, IInvoiceCodeHolder, IInvoicePayment } from "../../model/Invoice";
import { OptionalPartial } from "../../utilities/generics";
import { vasaloppetDateGetterFormatter } from "../../utilities/date";
import withToaster, { IToasterContext } from "../Common/Toaster";
import { B2bCompany } from "../../model/B2bCompany";
import CollectExternalRefDialog, { ExternalRefType } from "./CollectExternalRefDialog";
import { ApiBackend } from "../../providers/apibackend";
import { ListInvoicesParameters } from "../../providers/models";
import ManageOrder from "../Orders/ManageOrder";
import { IColumnDefinition } from "../Common/EnhancedTable/models";
import SearchFilter, { Filters } from "../Common/SearchFilter/SearchFilter";
import EnhancedTable from "../Common/EnhancedTable/EnhancedTable";
import { SearchFilterType, amountFormatter, entityTypeFormatter, getDefaultSearchFilter, hasAmount, hasNumItems, isCodeHolder, isPayment, numItemsFormatter } from "./utils";

type UnionTableType = IInvoice & OptionalPartial<IInvoicePayment, "amount" | "pointOfSale"> & OptionalPartial<IInvoiceB2bCustomProduct, "amount" | "numItems"> & OptionalPartial<IInvoiceCodeHolder, "createdBy">;

const ManageInvoices = ({ classes, showToast }: RouteComponentProps & WithStyles & IToasterContext) => {
    const [loading, setLoading] = useState(false);
    const [companies, setCompanies] = useState([] as B2bCompany[]);
    const [pointOfSales, setPointOfSales] = useState([] as { name: string; value: string; }[]);
    const [invoices, setInvoices] = useState(null as IInvoice[]);
    const [searchFilter, setSearchFilter] = useState(getDefaultSearchFilter());
    const [selected, setSelected] = React.useState<readonly IInvoice[]>([]);
    const [initialized, setInitialized] = useState(false);
    const [displayOrder, setDisplayOrder] = useState(null as string);
    const [showCollectExternalRefs, setShowCollectExternalRefs] = useState(false);
    const [externalRefs, setExternalRefs] = useState([] as ExternalRefType[]);

    const busyLoading = useRef<boolean>(false);
    const didMount = useRef<boolean>(false);
    const backend = new ApiBackend();
    const maxRows = 500;
    const empty = "---";

    const setBusyLoading = (value: boolean): void => {
        busyLoading.current = value;
        setLoading(value);
    };

    useEffect(() => {
        didMount.current = true;
        const doWork = async (): Promise<void> => {
            const work = [
                backend.listPointOfSales(),
                backend.listB2bCompanies({ includeArchived: true })
            ];

            const [posRes, companyRes] = await Promise.all(work);

            setCompanies(companyRes as B2bCompany[]);
            setPointOfSales(posRes.map(x => {
                return {
                    name: x.name,
                    value: x.id
                };
            }));
            setInitialized(true);
        }

        doWork();

        // unmount
        return () => { didMount.current = false };
    }, []);

    useEffect(() => {
        performSearch();
    }, [searchFilter]);

    useEffect(() => {
        if (!!externalRefs && externalRefs.length > 0) {
            save();
        }
    }, [externalRefs]);

    const handleRequestSearchFilterChange = (searchFilter: SearchFilterType): void => {
        setSearchFilter(searchFilter);
    };

    const handleOpenSource = (item: IInvoice) => (e: React.MouseEvent<unknown>): void => {
        e.stopPropagation();
        if (item.entityType === "Payment") {
            setDisplayOrder(item.sourceId as string);
        } else if (item.entityType === "CodeHolder") {
            window.open(`/codes/manage/${item.sourceId}`);
        }
    };

    const performSearch = async (): Promise<void> => {
        if (!didMount.current || busyLoading.current || !initialized) {
            return;
        }

        setBusyLoading(true);
        try {
            const params: ListInvoicesParameters = {
                ...searchFilter,
                begin: 0,
                limit: maxRows
            };

            const invoicesResponse = await backend.listInvoices(params);
            if (didMount.current) {
                if (!!invoicesResponse) {
                    setInvoices(invoicesResponse);
                }

                setBusyLoading(false);
            }
        } catch (e) {
            showToast("Någonting gick fel vid hämtning av betalningar", "error");
            setBusyLoading(false);
        }
    };

    const save = async (): Promise<void> => {
        const mapper = <T extends string | number>(item: ExternalRefType) => {
            return {
                id: item.sourceId as T,
                externalRef: item.externalRef
            };
        };

        const payments = externalRefs
            .filter(x => x.entityType === "Payment")
            .map(x => {
                return mapper<number>(x);
            });

        const codeHolders = externalRefs
            .filter(x => x.entityType === "CodeHolder")
            .map(x => {
                return mapper<string>(x);
            });

        const products = externalRefs
            .filter(x => x.entityType === "B2bCustomProduct")
            .map(x => {
                return mapper<string>(x);
            });

        const response = await backend.setInvoiceStatus(payments, codeHolders, products, "CREATED");

        if (response) {
            performSearch();
        } else {
            showToast("Någonting gick fel vid sparning", "error");
        }
    };

    const renderOrderDialog = (): JSX.Element => {
        return <ManageOrder
            orderId={displayOrder}
            close={() => {
                setDisplayOrder(null);
            }}
        />
    };

    const columnDefinitions: IColumnDefinition<UnionTableType>[] = [
        {
            propName: "presentationName",
            label: "Id",
            sortable: false,
            renderCell: (row) => {
                return <Typography style={{ textDecoration: 'underline', cursor: 'pointer' }}
                    onClick={handleOpenSource(row)}>
                    {row.presentationName}
                </Typography>
            }
        },
        {
            propName: "entityType",
            label: "Typ",
            valueFormatter: (row) => entityTypeFormatter(row),
            sortValueGetter: (row) => entityTypeFormatter(row)
        },
        {
            propName: "b2bId",
            label: "Företag",
            valueFormatter: (row) => companies.find(x => x.id === row.b2bId)?.name
        },
        {
            propName: "created",
            label: "Skapad",
            valueFormatter: (row) => vasaloppetDateGetterFormatter(row.created, empty)
        },
        {
            propName: "numItems",
            label: "Antal",
            valueFormatter: (row) => numItemsFormatter(row),
            sortValueGetter: (row) => numItemsFormatter(row),
        },
        {
            propName: "amount",
            label: "Summa",
            valueFormatter: (row) => amountFormatter(row),
            sortValueGetter: (row) => amountFormatter(row),
            summarize: (rows: UnionTableType[]) => {
                const sum = rows.reduce((sum, row) => sum + (row.amount ? row.amount : 0), 0);
                return `Summa: ${sum} kr`;
            },
        },
        {
            propName: "pointOfSale",
            label: "Försäljningsställe",
            valueFormatter: (row) => isPayment(row) ? row.pointOfSale?.name : empty,
            sortValueGetter: (row) => isPayment(row) ? row.pointOfSale?.name : empty,
        },
        {
            propName: "invoiceCreated",
            label: "Faktura skapad",
            valueFormatter: (row) => vasaloppetDateGetterFormatter(row.invoiceCreated, empty)
        },
        {
            propName: "invoiceCreatedBy",
            label: "Faktura skapad av"
        },
        {
            propName: "externalRef",
            label: "Extern referens"
        },
        {
            propName: "createdBy",
            label: "Skapad av",
            valueFormatter: (row) => isCodeHolder(row) ? row.createdBy : empty,
            sortValueGetter: (row) => isCodeHolder(row) ? row.createdBy : empty,
        }
    ];

    const isMarkInvoiceAsCreatedDisabled = (): boolean => {
        if (!selected || selected.length === 0) {
            return true;
        }

        return selected.some(x => x.invoiceCreated);
    };

    const getTooltipContent = (item: IInvoice): JSX.Element => {
        const items: Map<string, string | number> = new Map<string, string>();
        items.set("Id", item.id);
        items.set("Typ", entityTypeFormatter(item));

        if (!!item.b2bId) {
            items.set("Företag", companies.find(x => x.id === item.b2bId)?.name);
        }

        if (hasNumItems(item)) {
            items.set("Antal", `${item.numItems}`);
        }

        if (hasAmount(item)) {
            items.set("Summa", `${item.amount} kr`);
        }

        if (isPayment(item) && !!item.pointOfSale) {
            items.set("Försäljningsställe", item.pointOfSale?.name);
        }

        return (
            <div key={item.id}>
                {Array.from(items).map((x, idx) => {
                    return (<Fragment key={idx}><p>{`${x[0]}: ${x[1]}`}</p></Fragment>);
                })}
            </div>
        );
    };

    const render = (): JSX.Element => {
        if (!initialized) {
            return null;
        }

        let externalRefs: ExternalRefType[];
        if (!!selected) {
            externalRefs = selected.map(x => {
                return {
                    sourceId: x.id,
                    entityType: x.entityType,
                    presentationName: x.presentationName,
                    externalRef: "",
                    tooltipContent: getTooltipContent(x)
                }
            });
        }

        return <>
            <SearchFilter<SearchFilterType>
                id={"manage-invoice-filter"}
                filters={{
                    "fromDate": {
                        id: "filter-fromdate",
                        type: "Date",
                        label: "Fråndatum",
                        size: 3,
                        defaultValue: searchFilter.fromDate
                    },
                    "toDate": {
                        id: "filter-todate",
                        type: "Date",
                        label: "Tilldatum",
                        size: 3,
                        defaultValue: searchFilter.toDate
                    },
                    "invoiceStatus": {
                        id: "filter-invoice-status",
                        type: "GenericSelect",
                        label: "Fakturastatus",
                        size: 3,
                        defaultValue: searchFilter.invoiceStatus,
                        itemDefinition: {
                            itemValueType: "string",
                            items: [
                                { name: "Ohanterad", value: "UNMANAGED" },
                                { name: "Skapad", value: "CREATED" }
                            ]
                        }
                    },
                    "company": {
                        id: "filter-company",
                        type: "Company",
                        label: "Företag",
                        size: 3,
                        includeArchived: true,
                        multiple: true
                    },
                    "orderPointOfSale": {
                        id: "filter-point-of-sale",
                        type: "GenericSelect",
                        label: "Försäljningsställe (endast för Order)",
                        size: 3,
                        defaultValue: pointOfSales[0].value,
                        itemDefinition: {
                            itemValueType: "string",
                            items: pointOfSales
                        },
                        clearable: false
                    },
                    "orderPublicId": {
                        id: "filter-order-public-id",
                        type: "DebouncedText",
                        label: "OrderId (endast för Order)",
                        size: 3
                    },
                    "codeHolderName": {
                        id: "filter-codeholder-name",
                        type: "DebouncedText",
                        label: "Namn på kodhållare (endast för Kod)",
                        size: 3
                    },
                    "codeHolderInvoiceDescription": {
                        id: "filter-codeholder-invoice-description",
                        type: "DebouncedText",
                        label: "Fakturabeskrivning på kodhållare (endast för Kod)",
                        size: 3
                    },
                    "externalRef": {
                        id: "filter-external-ref",
                        type: "DebouncedText",
                        label: "Märkning",
                        size: 3
                    },
                    "entityType": {
                        id: "filter-entity-type",
                        type: "GenericSelect",
                        label: "Typ",
                        size: 3,
                        defaultValue: "ALL",
                        itemDefinition: {
                            itemValueType: "string",
                            items: [
                                { name: "Alla", value: "ALL" },
                                { name: "Order", value: "Payment" },
                                { name: "Kod", value: "CodeHolder" },
                                { name: "Anpassad produkt", value: "B2bCustomProduct" }
                            ]
                        }
                    }, "codeHolderCreatedBy": {
                        id: "filter-codeholder-created-by",
                        type: "DebouncedText",
                        label: "Skapad av (endast för Kod)",
                        size: 3
                    }
                }}
                persist={true}
                onInit={(filter: Filters<SearchFilterType>) => {
                    handleRequestSearchFilterChange(filter as unknown as SearchFilterType);
                }}
                onChange={(filter: Filters<SearchFilterType>) => {
                    handleRequestSearchFilterChange(filter as unknown as SearchFilterType);
                }}
            />
            <Grid container className={classes.root} spacing={2}>
                <Grid item xs={12}>
                    <Card>
                        <CardHeader className={classes.cardHeader}
                            title={
                                <Fragment>
                                    <Typography variant="h5" style={{ display: "inline" }}>Att fakturera </Typography>
                                    <RefreshIcon
                                        style={{ display: "inline", verticalAlign: "middle" }}
                                        onClick={performSearch}
                                    />
                                </Fragment>
                            }
                        />
                        <CardContent>
                            {loading &&
                                <LinearProgress color="secondary" />
                            }
                            <EnhancedTable<IInvoice>
                                columnDefinitions={columnDefinitions}
                                data={invoices ?? []}
                                pageSize={10}
                                maxRows={maxRows}
                                paginationMode="client"
                                sortingMode="client"
                                sortModel={{
                                    sortBy: "created",
                                    sortOrder: "desc"
                                }}
                                loading={loading}
                                dense
                                selectable
                                showFooter={true}
                                onSelectChanged={(rows: IInvoice[]) => {
                                    setSelected(rows);
                                }}
                            />
                        </CardContent>
                        <CardActions>
                            <Button size="small" color="secondary"
                                disabled={isMarkInvoiceAsCreatedDisabled()}
                                onClick={() => {
                                    setShowCollectExternalRefs(true);
                                }}
                            >
                                Markera valda som redo att fakturera
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>
            </Grid>
            {!!displayOrder && renderOrderDialog()}
            {showCollectExternalRefs &&
                <CollectExternalRefDialog
                    items={externalRefs}
                    onAbort={() => {
                        setExternalRefs([]);
                        setShowCollectExternalRefs(false);
                    }}
                    onSave={(values) => {
                        setExternalRefs(values);
                        setShowCollectExternalRefs(false);
                    }}
                />
            }
        </>;
    };

    return render();
};

const useStyles = ({ palette, spacing }: Theme) => createStyles({
    cardHeader: {
        background: palette.secondary.main,
        color: palette.secondary.contrastText,
        padding: 3
    },
    photo: {
        height: "30px",
        verticalAlign: "middle",
        borderRadius: "10px"
    },
    root: {

    },
    form: {
        "& > *": {
            margin: spacing(1),
            width: "25ch",
        },
        "& label.Mui-focused": {
            color: palette.secondary.main,
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: palette.secondary.main,
        },
    }
});

export default withStyles(useStyles)(withRouter(withToaster(ManageInvoices)));
