export class Person {
    id: string;
    externalId: string;
    firstName: string;
    lastName: string;
    email: string;
    birthDay: string;
    gender: 'male' | 'female';
    nationality: string;
    mobileNumber: string;
    address: string;
    address_line2: string;
    postCode: string;
    city: string;
    status: string;
    country: string;
    county: string;
    ExternalId: string;
    vasaId: string;
    fisCode: string;
    excludeWeb: boolean;
    deceased: boolean
    veteranId: string;
    noCommercial: boolean;
    noMailExceptConfirmation: boolean;
    noDataToThirdParty: boolean;
    forcedCommunicationLanguage: 'sv' | 'en'| null;
    counters?: PersonCounter[];
}

export class PersonCounter {
    counter_key: string;
    name: string;
    count: number;
}

export interface IListPersonsResponse {
    persons: Person[];
    count: number;
}