import { Grid, Select } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { ApiBackend } from '../../../providers/apibackend';
import { Product, raceDataProductTypes } from '../../../model/Product';
import { getSortedOccations, getSortedPricegroups, getSortedProducts, getSortedVariants } from './utils';

interface Props {
    selectionChanged: (product: Product, variantId: string, priceGroupId: string, occationId: string, numberOfProducts: number) => void;
}

const ManageOrderAddItem = ({ selectionChanged }: Props) => {
    const [products, setProducts] = useState(null as Product[]);
    const [product, setProduct] = useState(null as Product);
    const [selProduct, setSelProduct] = useState('');
    const [selProductVariant, setSelProductVariant] = useState('');
    const [selProductVariantPriceGroup, setSelProductVariantPriceGroup] = useState('');
    const [selProductVariantOccation, setSelProductVariantOccation] = useState('');
    const [selNumberOfProducts, setSelNumberOfProducts] = useState(1);

    useEffect(() => {
        let back = new ApiBackend();
        back.listProducts().then((prods) => {
            prods = prods.filter(x => !raceDataProductTypes.some(rp => rp === x.type));
            setProducts(getSortedProducts(prods));
        });
    }, []);

    useEffect(() => {
        setSelProductVariant(null);
        setSelProductVariantOccation(null);
        setSelProductVariantPriceGroup(null);
        setSelNumberOfProducts(1);

        if (selProduct) {
            let back = new ApiBackend();
            back.getProduct(selProduct).then((data) => {
                setProduct(data);
            });
        }
        else {
            selectionChanged(null, selProductVariant, selProductVariantPriceGroup, selProductVariantOccation, selNumberOfProducts);
        }
    }, [selProduct]);

    useEffect(() => {
        const product = selProduct ? products.find(x => x.id == selProduct) : null;
        selectionChanged(product, selProductVariant, selProductVariantPriceGroup, selProductVariantOccation, selNumberOfProducts);
    }, [selProductVariant, selProductVariantPriceGroup, selProductVariantOccation, selNumberOfProducts]);

    const numItemsOptionValues = (): number[] => {
        return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    };

    const render = () => {
        const productVariant = product && selProductVariant ? product.variants.find(x => x.Id == selProductVariant) : null;
        const isRaceProduct = raceDataProductTypes.some(x => x === product?.type);

        return (
            <Grid container spacing={2}>
                <Grid item xs={6}>Produkt</Grid>
                <Grid item xs={6}>
                    <Select fullWidth native
                        value={selProduct ?? ''}
                        onChange={(ev) => {
                            setSelProduct(ev.target.value as string);
                        }}
                    >
                        <option value=''></option>
                        {products && products.map((prod) => {
                            return <option value={prod.id}>{prod.name}</option>
                        })}
                    </Select>
                </Grid>

                <Grid item xs={6}>Variant</Grid>
                <Grid item xs={6}>
                    <Select fullWidth native
                        value={selProductVariant ?? ''}
                        onChange={(ev) => {
                            setSelProductVariant(ev.target.value as string);
                        }}
                    >
                        <option value=''></option>
                        {product && getSortedVariants(product.variants).map((prod) => {
                            return <option value={prod.Id}>{prod.Name}</option>
                        })}
                    </Select>
                </Grid>

                {productVariant && productVariant.occations && <>
                    <Grid item xs={6}>Tillfälle</Grid>
                    <Grid item xs={6}>
                        <Select fullWidth native
                            value={selProductVariantOccation ?? ''}
                            onChange={(ev) => {
                                setSelProductVariantOccation(ev.target.value as string);
                            }}
                        >
                            <option value=''></option>
                            {productVariant && getSortedOccations(productVariant.occations).map((prod) => {
                                return <option value={prod.id}>{prod.name}</option>
                            })}
                        </Select>
                    </Grid></>
                }

                <Grid item xs={6}>Prisgrupp</Grid>
                <Grid item xs={6}>
                    <Select fullWidth native
                        value={selProductVariantPriceGroup ?? ''}
                        onChange={(ev) => {
                            setSelProductVariantPriceGroup(ev.target.value as string);
                        }}
                    >
                        <option value=''></option>
                        {productVariant && getSortedPricegroups(productVariant.priceGroups).map((prod) => {
                            return <option value={prod.id}>{prod.Name} ({prod.price}kr)</option>
                        })}
                    </Select>
                </Grid>

                {product && !isRaceProduct && <>
                    <Grid item xs={6}>Antal</Grid>
                    <Grid item xs={6}>
                        <Select fullWidth native
                            value={selNumberOfProducts}
                            onChange={(ev) => {
                                setSelNumberOfProducts(+ev.target.value);
                            }}
                        >
                            {numItemsOptionValues().map((item) => {
                                return <option key={item} value={item}>{item}</option>
                            })}
                        </Select>
                    </Grid></>
                }
            </Grid>
        );
    };

    return render();
}

export default ManageOrderAddItem;
