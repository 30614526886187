import { Card, CardContent, CardHeader, createStyles, Divider, Grid, Theme, withStyles, WithStyles } from '@material-ui/core';
import React, { Fragment } from 'react';
import { Order } from '../../model/Order';
import OrderRowMetadata from './OrderRowMetadata';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import Modal from '@material-ui/core/Modal/Modal';
import OrderRowEntry from './OrderRowEntry';
import { OrderItem } from '../../model/OrderItem';
import { EntryAddonProduct } from '../../model/Entry';
import { vasaloppetDateTimeGetterFormatter } from '../../utilities/date';
import { OrderCode } from '../../model/OrderCode';

interface Props {
    order: Order;
    onNavigateToOrder: (orderId: string) => void;
    onForceSave: () => Promise<void>;
    onChange: () => void;
}

interface State {
    saving: boolean;
}

class ManageOrderItems extends React.Component<Props & WithStyles, State> {
    constructor(props: Props & WithStyles) {
        super(props);
        this.state = { saving: false };
    }

    private navigateToOrder(publicId: string) {
        this.props.onNavigateToOrder(publicId)
    }

    private formatRevertReason(reason?: string) {

        if (!reason) {
            return null;
        }

        if (reason.indexOf("Ombokad till ") < 0) {
            return <span>{reason}</span>;
        }

        const orderId = reason.split(":")[0].replace("Ombokad till ", "");
        return <><span>Ombokad till </span><a href="#" style={{ textDecoration: 'underline' }} onClick={() => { this.navigateToOrder(orderId); }}>{orderId}</a><span>:{reason.split(":")[1]}</span></>
    }

    private formatOrderDiscountCodeText(orderCode: OrderCode) {
        if (orderCode.code !== "OMBOKNINGSVÄRDE" || orderCode.description.indexOf("Ombokningvärde från order") < 0) {
            return `${orderCode.code} (${orderCode.description})`;
        }

        const orderId = orderCode.description.split(":")[1].replaceAll(" ", "");
        return <><span>{orderCode.code} </span><span> Ombokningvärde från order: </span><a href="#" style={{ textDecoration: 'underline' }} onClick={() => { this.navigateToOrder(orderId); }}>{orderId}</a><span>)</span></>;
    }

    render() {
        const { classes } = this.props;
        const o = this.props.order;

        const itemPrice = (orderItem: OrderItem): JSX.Element => {
            if (orderItem.originalItemPrice === null || orderItem.originalItemPrice === undefined || orderItem.originalItemPrice === orderItem.itemPrice) {
                return <span>{orderItem.itemPrice} kr</span>;
            }

            return <><span style={{ textDecoration: "line-through" }}>{orderItem.originalItemPrice} kr</span> <span>{orderItem.itemPrice} kr</span></>;
        };

        return <Card>
            <CardHeader className={classes.cardHeader} title={<Fragment>Beställning</Fragment>} />
            <CardContent>
                {this.state.saving ? <Modal
                    open={true}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    <CircularProgress color="primary" />
                </Modal> : null}
                <Grid container spacing={2}>
                    <Grid item xs={2}><b>Antal</b></Grid>
                    <Grid item xs={6}><b>Produkt</b></Grid>
                    <Grid item xs={2}><b>à pris</b></Grid>
                    <Grid item xs={2}><b>Delsumma</b></Grid>
                    {o.items.map((orderItem, idx) => {
                        let name = orderItem.productName + " (" + orderItem.variantName + " / " + orderItem.priceGroupName + ")";
                        if (orderItem.occationName) {
                            name = orderItem.productName;
                        }
                        const bg = { background: idx % 2 == 0 ? "#eeeeee" : "" }
                        var s = { ...{ textDecoration: orderItem.reverted ? "line-through" : "" }, ...bg };
                        return <Fragment key={idx}>
                            <Grid style={s} item xs={2}>{orderItem.numberOfProducts} st</Grid>
                            <Grid style={s} item xs={6}>{name}</Grid>
                            <Grid style={s} item xs={2}>{itemPrice(orderItem)}</Grid>
                            <Grid style={s} item xs={2}>{orderItem.itemPrice * orderItem.numberOfProducts} kr</Grid>
                            {(orderItem.metadata || orderItem.occationName || orderItem.product.type == "insurance" || orderItem.product.name.startsWith("Enervit")) &&
                                <OrderRowMetadata rowStyle={{ ...s, ...bg }} item={orderItem}
                                    onUpdateMetadata={async (metadata) => {
                                        orderItem.metadata = metadata;
                                        return this.props.onForceSave();
                                    }
                                    } />
                            }
                            {(orderItem.entry) &&
                                <OrderRowEntry
                                    rowStyle={{ ...s, ...bg }}
                                    item={orderItem}
                                    onConvertEntryAddonToOwnItem={(toRemoveEntryAddon: EntryAddonProduct, newOrderItem: OrderItem) => {
                                        o.items.push(newOrderItem);
                                        orderItem.entry.addOns = orderItem.entry.addOns.filter(x => x != toRemoveEntryAddon);
                                        this.props.onForceSave();
                                    }}
                                    onChange={() => {
                                        this.props.onChange();
                                    }}
                                />
                            }
                            {orderItem.code && (<>
                                <Grid item style={bg} xs={2}></Grid>
                                <Grid item style={bg} xs={6}>
                                    Kod: {orderItem.code}
                                </Grid>
                                <Grid item style={bg} xs={4}></Grid>
                            </>)}
                            {orderItem.reverted && <>
                                <Grid item style={bg} xs={2} ></Grid>
                                <Grid item style={{ ...bg, ...{ color: 'red' } }} xs={10}>
                                    <b>{vasaloppetDateTimeGetterFormatter(orderItem.revertedWhen)} ({orderItem.revertedBy}):</b> {this.formatRevertReason(orderItem.revertedReason)}
                                </Grid></>
                            }
                        </Fragment>
                    })}
                    {o.discountCodes && o.discountCodes.map((code, idx) => {
                        return <Fragment key={idx}>
                            <Grid item xs={2}>{1}</Grid>
                            <Grid item xs={6}>{this.formatOrderDiscountCodeText(code)}</Grid>
                            <Grid item xs={2}>-{code.value} kr</Grid>
                            <Grid item xs={2}>-{code.value} kr</Grid>
                        </Fragment>
                    })}

                    <Grid item xs={12}>
                        <Divider />
                    </Grid>

                    <Grid item xs={12}>Totalt: <b>{o.totalSum} kr</b></Grid>
                </Grid>
            </CardContent>
        </Card>
    }
}

const useStyles = ({ palette, spacing }: Theme) => createStyles({
    cardHeader: {
        background: palette.secondary.main,
        color: palette.secondary.contrastText,
        padding: 3
    },
    form: {
        '& > *': {
            margin: spacing(1),
            width: '25ch',
        },
        '& label.Mui-focused': {
            color: palette.secondary.main,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: palette.secondary.main,
        },
    }
});

export default withStyles(useStyles)(ManageOrderItems);
