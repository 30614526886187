import { OrderItem } from "../model/OrderItem";

export class OrderHelpers {
    public static calculateOrderItemSum(oi: OrderItem) {
        let sum = oi.numberOfProducts * oi.itemPrice;
        if (oi.entry && oi.entry.addOns) {
            for (let i = 0; i < oi.entry.addOns.length; i++) {
                sum += oi.entry.addOns[i].productVariantPrice;
            }
        }
        return sum;
    }
}
