import React, { useState } from "react";
import { Entry } from "../../../model/Entry"
import { Button, Card, CardActions, CardContent, CardHeader, Grid, MenuItem } from "@material-ui/core";
import { FormikProps } from "formik";
import FormSelectField from "../../Common/FormSelectField";
import AddEntryPersonalBibDialog from "./AddEntryPersonalBibDialog/AddEntryPersonalBibDialog";

interface IProps extends FormikProps<Entry> {
    onBibBought: () => void;
}

const EntryPersonalBibCard = (props: IProps) => {
    const [showAddPersonalBib, setShowAddPersonalBib] = useState(false);

    const render = () => {
        const hasPersonalBib = props.values.personalBib;

        return (<>
            <Card>
                <CardHeader
                    title="Personlig nummerlapp"
                />
                {hasPersonalBib &&
                    <CardContent>
                        <Grid container spacing={2} style={{ width: "100%" }}>
                            <Grid item xs={4}>
                                <FormSelectField {...props}
                                    propName="handoutLocation"
                                    label="Utlämningsställe"
                                >
                                    <MenuItem key="Mora" value="Mora">Mora</MenuItem>
                                    <MenuItem key="Sälen" value="Sälen">Sälen</MenuItem>
                                </FormSelectField>
                            </Grid>
                            <Grid item xs={4}>
                                <FormSelectField {...props}
                                    propName="bibSize"
                                    label="Storlek"
                                >
                                    <MenuItem key="S-L" value="S-L">S-L</MenuItem>
                                    <MenuItem key="XL-XXL" value="XL-XXL">XL-XXL</MenuItem>
                                </FormSelectField>
                            </Grid>
                        </Grid>
                    </CardContent>
                }
                <CardActions>
                    {!hasPersonalBib &&
                        <Button size="small" color="secondary"
                            disabled={props.dirty}
                            onClick={() => {
                                setShowAddPersonalBib(true);
                            }}
                        >
                            Lägg till personlig nummerlapp
                        </Button>
                    }
                </CardActions>
            </Card>

            {showAddPersonalBib &&
                <AddEntryPersonalBibDialog
                    entry={props.values}
                    onComplete={() => {
                        setShowAddPersonalBib(false);
                        props.onBibBought();
                    }}
                    onAbort={() => {
                        setShowAddPersonalBib(false);
                    }}
                />
            }
        </>);
    };

    return render();
};

export default EntryPersonalBibCard;