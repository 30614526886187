import { Button, Checkbox, FormControl, FormControlLabel, FormHelperText, Grid, InputLabel, Select, TextField, Typography } from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import { cloneDeep } from "lodash";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { Event } from "../../../model/Event";
import { Order, initNewOrder } from "../../../model/Order";
import { OrderItem } from "../../../model/OrderItem";
import { Product, ProductType, raceDataProductTypes } from "../../../model/Product";
import { ApiBackend } from "../../../providers/apibackend";
import { OrderHelpers } from "../../../utilities/OrderHelpers";
import AddEntryCustomFields from "../../Entries/Entry/AddEntryDialog/AddEntryCustomFields";
import SelectPointOfSale from "../SelectPointOfSale";
import { getSortedEvents, getSortedOccations, getSortedPricegroups, getSortedProducts, getSortedVariants } from "./utils";
import StartGroupSelector from "../../Common/StartGroupSelector";
import { vasaloppetTimeGetterFormatter } from "../../../utilities/date";
import { isValidCode } from "../../Entries/Entry/AddEntryDialog/utils";
import withAlert, { IAlertContext } from "../../Common/dialog/Alert";
import ProductTypeSelect from "../../Common/ProductTypeSelect";
import { ProductVariantPriceGroup } from "../../../model/ProductVariantPriceGroup";
import { Alert } from "@material-ui/lab";
import { newEmptyEntry } from "../../../model/Entry";

export type PayWith = "none" | "account" | "beloppsreglering" | "link" | "repay" | "card_swish";

interface Props {
    sourceOrderId: string;
    text: string;
    item: OrderItem;
    onTextChanged: (text: string) => void;
    onOrderChanged: (order: Order, formIsValid: boolean) => void;
    onSendOrderEmailChanged: (sendemail: boolean) => void;
    onSetHandleDiffWith: (handleDiffWith: PayWith, account: string, comment: string, formIsValid: boolean) => void;
}

const getInitOrder = (item: OrderItem, rebookValue: number): Order => {
    const initOrder = initNewOrder();
    const itemClone = cloneDeep(item);
    // Reset entryCodes from original
    if (itemClone.entry) {
        itemClone.entry.usedEntryCode = null;
    }
    itemClone.code = null;
    initOrder.items = [itemClone];
    initOrder.items[0].Id = null;

    if (initOrder.items[0].entry) {
        initOrder.items[0].entry.created = null;
        initOrder.items[0].entry.updated = null;
        initOrder.items[0].entry.eventKey = null;
        initOrder.items[0].entry.id = null;
        initOrder.items[0].entry.MT_regId = null;
        initOrder.items[0].entry.externalId = null;
        initOrder.items[0].entry.MT_MTQRcontent = null;
        initOrder.items[0].entry.status = "NOTACTIVE";
    }

    return initOrder;
};

const getOrderedPriceGroupOrDefault = (orderedItem: OrderItem, products: Product[]): ProductVariantPriceGroup => {
    const orderedProduct = products.find(p => p.id === orderedItem.productId);
    const orderedVariant = orderedProduct.variants.find(v => v.Id === orderedItem.productVariantId);
    const orderedPriceGroup = orderedVariant.priceGroups.find(pg => pg.id === orderedItem.productVariantPriceGroupId);

    if (orderedPriceGroup) {
        return orderedPriceGroup;
    }

    return orderedVariant.priceGroups[0];
};

type ProductTypeUnion = ProductType | "ALL";

const ManageOrderRebookItem = ({ onTextChanged, onOrderChanged, onSetHandleDiffWith, text, item, sourceOrderId, onSendOrderEmailChanged, showAlert }: Props & IAlertContext) => {
    const productTypeSelectWildCard = "ALL";

    const [loadingProducts, setLoadingProducts] = useState(true);
    const [products, setProducts] = useState(null as Product[]);
    const [filteredProducts, setFilteredProducts] = useState(null as Product[]);
    const [productType, setProductType] = useState(productTypeSelectWildCard as ProductTypeUnion);
    const [events, setEvents] = useState(null as Event[]);
    const [event, setEvent] = useState(null as string);
    const [productVariantPriceGroups, setProductVariantPriceGroups] = useState(getSortedPricegroups(item.product.variants.find(x => x.Id === item.productVariantId).priceGroups));
    const [productVariantOccations, setProductVariantOccations] = useState(getSortedOccations(item.product.variants.find(x => x.Id === item.productVariantId).occations));
    const [payWith, setPayWith] = useState("none" as PayWith);
    const [code, setCode] = useState(null as string);
    const [sendOrderEmail, setSendOrderEmail] = useState(true);
    const [paymentComment, setPaymentComment] = useState("");
    const [pointOfSale, setPointOfSale] = useState(null as string);
    const [alreadyRebooked, setAlreadyRebooked] = useState(false);

    const rebookValue = item.originalItemPrice ? item.originalItemPrice * item.numberOfProducts : item.itemPrice * item.numberOfProducts; // Original price med fallback på nuvarande pris
    const [order, setOrder] = useState(getInitOrder(item, rebookValue));

    const initialized = useRef<boolean>(false);
    const backend = new ApiBackend();

    useEffect(() => {

        const init = async () => {
            const events = await backend.listEvents();
            const prods = await backend.listProducts();
            const sourceOrder = await backend.retreiveOrder(sourceOrderId);
            const sourceOrderItem = sourceOrder.items.find(x => x.Id === item.Id);
            setAlreadyRebooked(sourceOrderItem.reverted || (sourceOrderItem.entry && sourceOrderItem.entry.status !== "ACTIVE"));
            setEvents(getSortedEvents(events));

            if (item.productId) {
                const prod = prods.find(x => x.id == item.productId)
                if (prod) {
                    setEvent(prod.EventId);

                    const originalProductType = prod.type;
                    let nextFilteredProducts = prods.filter(p => p.type === originalProductType);
                    nextFilteredProducts = nextFilteredProducts.filter(p => p.EventId === prod.EventId);
                    setFilteredProducts(nextFilteredProducts);
                    setProductType(originalProductType);
                }
            }

            setProducts(getSortedProducts(prods));

            // OrderItem.itemPrice/originalItemPrice samt order.totalSum baserat på pris från prisgrupp
            const orderedItem = order.items[0];
            const orderedPriceGroup = getOrderedPriceGroupOrDefault(orderedItem, prods);
            orderedItem.originalItemPrice = orderedPriceGroup.price;
            orderedItem.itemPrice = orderedPriceGroup.price;
            order.totalSum = orderedPriceGroup.price * orderedItem.numberOfProducts;

            setLoadingProducts(false);

            initialized.current = true;
        };

        init();
    }, []);

    useEffect(() => {
        if (!initialized.current) {
            return;
        }

        let nextFilteredProducts: Product[];
        if (productType === productTypeSelectWildCard) {
            nextFilteredProducts = products;
        } else {
            nextFilteredProducts = products.filter(p => p.type === productType);
        }

        nextFilteredProducts = nextFilteredProducts.filter(p => p.EventId === event);
        setFilteredProducts(nextFilteredProducts);
        clearOrderItem();
    }, [event, productType]);

    useEffect(() => {
        onOrderChanged(order, formIsValid());
    }, [order]);

    useEffect(() => {
        onSetHandleDiffWith(payWith, payWith == "account" ? pointOfSale : null, payWith == "account" ? paymentComment : null, formIsValid());
    }, [payWith, pointOfSale, paymentComment]);

    const formIsValid = () => {
        if (!order?.items && order?.items.length > 0) {
            return false;
        }

        const item = order.items[0];

        if (item.product?.startGroupAllocationActive && !(item.entry?.startgroup)) {
            return false;
        }

        if (toPay() != 0 && payWith == 'none') {
            return false;
        }

        return !!(
            order.items[0].productId &&
            order.items[0].productVariantId &&
            order.items[0].productVariantPriceGroupId);
    };

    const numItemsOptionValues = (): number[] => {
        if (order.items[0].numberOfProducts < 10) {
            return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
        }

        const items = [];
        for (let i = 0; i < order.items[0].numberOfProducts * 2; i++) {
            items.push(i + 1);
        }

        return items;
    };

    const setProduct = (product: string) => {
        setLoadingProducts(true);
        backend.getProduct(product).then((prod) => {
            order.items[0].productId = prod.id;
            order.items[0].product = prod;
            order.items[0].productName = prod.name;
            order.items[0].productVariantId = null;
            order.items[0].productVariantPriceGroupId = null;
            if (order.items[0].entry) {
                order.items[0].entry.addOns = [];
                order.items[0].entry.startgroup = null;
            }

            const isRaceProduct = raceDataProductTypes.some(x => x === prod.type);
            if (isRaceProduct && !order.items[0].entry) {
                order.items[0].entry = newEmptyEntry();
            } else if (!isRaceProduct && order.items[0].entry) {
                order.items[0].entry = null;
            }

            if (isRaceProduct) {
                order.items[0].numberOfProducts = 1;
            }

            order.totalSum = OrderHelpers.calculateOrderItemSum(order.items[0]);
            setOrder({ ...order });
            setLoadingProducts(false);
        });
    };

    const setProductVariant = (variant: string) => {
        order.items[0].productVariantId = variant;
        const variantInstance = order.items[0].product.variants.find(x => x.Id == variant);
        setProductVariantPriceGroups(getSortedPricegroups(variantInstance.priceGroups));
        setProductVariantOccations(getSortedOccations(variantInstance.occations));
        order.totalSum = OrderHelpers.calculateOrderItemSum(order.items[0]);
        setOrder({ ...order });
    };

    const setProductVariantPriceGroup = (priceGroup: string) => {
        order.items[0].productVariantPriceGroupId = priceGroup;
        const pg = productVariantPriceGroups.find(x => x.id == priceGroup);
        order.items[0].priceGroupName = pg.Name;
        order.items[0].itemPrice = pg.price;
        order.totalSum = OrderHelpers.calculateOrderItemSum(order.items[0]);
        setPayWith('none');
        setOrder({ ...order });
    };

    const setProductVariantOccation = (occation: string) => {
        order.items[0].productVariantOccationId = occation;
        const occ = productVariantOccations.find(x => x.id == occation);
        order.items[0].occationName = occ.name;
        order.totalSum = OrderHelpers.calculateOrderItemSum(order.items[0]);
        setOrder({ ...order });
    };

    const setNumberOfProducts = (num: string) => {
        order.items[0].numberOfProducts = +num;
        order.totalSum = OrderHelpers.calculateOrderItemSum(order.items[0]);
        setOrder({ ...order });
    };

    const setStartGroup = (startGroup: string) => {
        order.items[0].entry.startgroup = startGroup;
        setOrder({ ...order });
    };

    const enableDisableAddon = (addon: string) => {
        let oi = order.items[0];
        if (!oi || !oi.entry) {
            return;
        }

        if (!oi.entry.addOns) {
            oi.entry.addOns = [];
        }

        let current = oi.entry.addOns.find(x => x.productId == addon);
        if (!current) {
            let prod = products.find(x => x.id == addon);
            current = { productId: addon, productName: prod.name, productVariantId: prod.variants[0].Id, productVariantName: prod.variants[0].Name, productVariantPrice: prod.variants[0].priceGroups[0].price, productVariantPriceGroupId: prod.variants[0].priceGroups[0].id };
            oi.entry.addOns.push(current);
        } else {
            oi.entry.addOns = oi.entry.addOns.filter(x => x.productId != addon);
        }

        order.totalSum = OrderHelpers.calculateOrderItemSum(order.items[0]);
        setOrder({ ...order });
    };

    const setAddonVariant = (addon: string, variant: string) => {
        let oi = order.items[0];
        if (!oi || !oi.entry) {
            return;
        }

        if (!oi.entry.addOns) {
            oi.entry.addOns = [];
        }

        let current = oi.entry.addOns.find(x => x.productId == addon);
        if (current) {
            let prod = products.find(x => x.id == addon);
            let variantInstance = prod.variants.find(x => x.Id == variant);
            current.productVariantId = variant;
            current.productVariantName = variantInstance.Name;
            current.productVariantPriceGroupId = variantInstance.priceGroups[0].id;
            current.productVariantPrice = variantInstance.priceGroups[0].price;
            order.totalSum = OrderHelpers.calculateOrderItemSum(oi);
        }

        setOrder({ ...order });
    };

    const setAddonPriceGroup = (addon: string, priceGroup: string) => {
        let oi = order.items[0];
        if (!oi || !oi.entry) {
            return;
        }

        if (!oi.entry.addOns) {
            oi.entry.addOns = [];
        }

        let current = oi.entry.addOns.find(x => x.productId == addon);
        if (current) {
            let prod = products.find(x => x.id == addon);
            let variantInstance = prod.variants.find(x => x.Id == current.productVariantId);
            let priceGroupInstance = variantInstance.priceGroups.find(x => x.id == priceGroup);
            current.productVariantPriceGroupId = priceGroup;
            current.productVariantPrice = priceGroupInstance.price;
            order.totalSum = OrderHelpers.calculateOrderItemSum(oi);
        }

        setOrder({ ...order });
    };

    const sumDiscounts = () => {
        let sum = 0;
        if (order && order.discountCodes) {
            for (let i = 0; i < order.discountCodes.length; i++) {
                sum += order.discountCodes[i].value;
            }
        }

        return sum;
    };

    const updatePrice = (toEstimate: Order) => {
        backend.estimateOrder(toEstimate).then((ret) => {
            ret.order.totalSum = OrderHelpers.calculateOrderItemSum(ret.order.items[0]);

            if (!!order.createdOnPosId && !ret.order.createdOnPosId) {
                ret.order.createdOnPosId = order.createdOnPosId;
            }

            setOrder(ret.order);
        });
    };

    const handleRemoveOrderItemDiscountCode = async () => {
        order.items[0].code = null;
        order.items[0].entry.usedEntryCode = null;
        updatePrice(order);
    };

    const handleAddDiscountCode = async () => {
        const validationResult = await backend.validateProductDiscountCode(code, order.items[0].productId, order.items[0].productVariantId);

        const result = isValidCode(validationResult);
        if (!result) {
            showAlert("Felaktig kod", "Den angivna koden är inte giltig.");
            return;
        }

        let toEstimate = order;

        if (result === "ORDER") {
            toEstimate.discountCodes = [{ code: code, description: "", value: null }];
        } else {
            if (!!toEstimate.items[0].code) {
                showAlert("En anmälningskod är redan tillagd", "Ta bort den gamla anmälningskoden och försök igen.");
                return;
            }

            toEstimate.items[0].code = code;
            toEstimate.items[0].entry.usedEntryCode = code;
        }

        updatePrice(toEstimate);
    };

    const handleProductTypeChange = (nextValue: ProductTypeUnion) => {
        setProductType(nextValue);
    };

    const clearOrderItem = () => {
        order.items[0].productId = null;
        order.items[0].product = null;
        order.items[0].productName = null;
        order.items[0].productVariantId = null;
        order.items[0].productVariantPriceGroupId = null;
        order.items[0].productVariantOccationId = null;
        if (order.items[0].entry) {
            order.items[0].entry.addOns = [];
            order.items[0].entry.startgroup = null;
        }

        order.totalSum = OrderHelpers.calculateOrderItemSum(order.items[0]);
        setOrder({ ...order });
    };

    const toPay = () => {
        return order.totalSum - rebookValue - sumDiscounts();
    }

    const render = () => {
        if (loadingProducts) {
            return (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <LinearProgress color="secondary" />
                    </Grid>
                </Grid>
            );
        }

        if (alreadyRebooked) {
            return (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Alert severity="error">Denna anmälan är redan ombokad</Alert>
                    </Grid>
                </Grid>
            );
        }

        const hasStartGroupRequiredError = order.items[0]?.product?.startGroupAllocationActive && !order.items[0]?.entry?.startgroup;
        const selectedProductType = order.items[0]?.product?.type ?? productType;
        const isRaceProduct = raceDataProductTypes.some(x => x === selectedProductType);
        const hasSelectedProduct = selectedProductType !== "ALL";

        return (
            <Grid container spacing={2}>
                <Grid item xs={6}>Evenemang</Grid>
                <Grid item xs={6}>
                    <Select fullWidth native value={event ?? ""} onChange={(ev) => {
                        setEvent(ev.target.value as string)
                    }}>
                        <option value=""></option>
                        {events && events.map((event, idx) => {
                            return <option key={event.id} value={event.id}>{event.name}</option>
                        })}
                    </Select>
                </Grid>
                <Grid item xs={6}>Produkt</Grid>
                <Grid item xs={2}>
                    <ProductTypeSelect
                        native={true}
                        useFormWrapper={false}
                        wildCardSelect={productTypeSelectWildCard}
                        defaultValue={productType}
                        onChange={handleProductTypeChange}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Select fullWidth native value={order.items[0].productId ?? ""} onChange={(ev) => {
                        setProduct(ev.target.value as string)
                    }}>
                        <option value=""></option>
                        {filteredProducts && filteredProducts.map((prod, idx) => {
                            return <option key={prod.id} value={prod.id}>{prod.name}</option>
                        })}
                    </Select>
                </Grid>
                <Grid item xs={6}>Variant</Grid>
                <Grid item xs={6}>
                    <Select fullWidth native value={order.items[0].productVariantId ?? ""}
                        onChange={(ev) => {
                            setProductVariant(ev.target.value as string);
                        }}
                    >
                        <option value=""></option>
                        {order.items[0].product && getSortedVariants(order.items[0].product.variants).map((prod, idx) => {
                            return <option key={prod.Id} value={prod.Id}>{prod.Name}</option>
                        })}
                    </Select>
                </Grid>

                {!isRaceProduct && hasSelectedProduct && productVariantOccations && <>
                    <Grid item xs={6}>Tillfälle</Grid>
                    <Grid item xs={6}>
                        <Select fullWidth native value={order.items[0].productVariantOccationId ?? ""}
                            onChange={(ev) => {
                                setProductVariantOccation(ev.target.value as string);
                            }}
                        >
                            <option value=""></option>
                            {productVariantOccations && productVariantOccations.map((prod, idx) => {
                                return <option key={prod.id} value={prod.id}>{prod.name}</option>
                            })}
                        </Select>
                    </Grid>
                </>}

                <Grid item xs={6}>Prisgrupp</Grid>
                <Grid item xs={6}>
                    <Select fullWidth native value={order.items[0].productVariantPriceGroupId ?? ""} onChange={(ev) => { setProductVariantPriceGroup(ev.target.value as string) }}>
                        <option value=""></option>
                        {productVariantPriceGroups && productVariantPriceGroups.map((prod, idx) => {
                            return <option key={prod.id} value={prod.id}>{prod.Name}</option>
                        })}
                    </Select>
                </Grid>
                {!isRaceProduct && hasSelectedProduct && <>
                    <Grid item xs={6}>Antal</Grid>
                    <Grid item xs={6}>
                        <Select fullWidth native value={order.items[0].numberOfProducts ?? 1}
                            onChange={(ev) => {
                                setNumberOfProducts(ev.target.value as string);
                            }}
                        >
                            {numItemsOptionValues().map((num) => {
                                return <option key={num} value={num}>{num}</option>
                            })}
                        </Select>
                    </Grid>
                </>}
                {order.items[0].product?.startGroups && <>
                    <Grid item xs={6}>Startled</Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth error={hasStartGroupRequiredError}>
                            <StartGroupSelector
                                productIds={order.items[0].product?.id}
                                initialValue={order.items[0].entry?.startgroup || ""}
                                clearable
                                onChange={(ev) => {
                                    setStartGroup(ev.target.value as string);
                                }}
                                onClear={() => {
                                    setStartGroup(null);
                                }}
                                valueFormatter={(gr) => {
                                    const remainingSellable = gr.numberOfSellablePlaces - gr.numberOfOccupiedPlaces - gr.numberOfReservedPlaces;
                                    const remainingTotal = gr.maxParticipants - gr.numberOfOccupiedPlaces - gr.numberOfReservedPlaces; // Skippa efter dialog
                                    return `${gr.name}, ${vasaloppetTimeGetterFormatter(gr.startTime)}, ${remainingTotal} lediga (${remainingSellable} publikt lediga)`;
                                }}
                            />
                            {hasStartGroupRequiredError && <FormHelperText>Startledstilldelning är aktiv. Vänligen välj en startgrupp.</FormHelperText>}
                        </FormControl>
                    </Grid>
                </>}
                <Grid item xs={6}>Anledning till ombokning</Grid>
                <Grid item xs={6}><TextField fullWidth value={text ?? ""} onChange={(ev) => {
                    onTextChanged(ev.target.value);
                }} />
                </Grid>

                <Grid item xs={12} style={{ borderTop: "1px dashed", background: "#eeeeee" }}>Tillägg</Grid>
                <Grid item xs={12}>
                    {products && order.items[0].product && order.items[0].product.addOnProducts &&
                        order.items[0].product.addOnProducts.map((addon) => {
                            const prod = products.find(x => x.id == addon);
                            if (prod) {
                                const addonInUse = order.items[0].entry && order.items[0].entry.addOns && order.items[0].entry.addOns.find(x => x.productId == addon);
                                const variant = addonInUse ? prod.variants.find(x => x.Id == addonInUse.productVariantId) : null;

                                //Skip insurance products for now..
                                if (!addonInUse && prod.type == "insurance") {
                                    return;
                                }

                                return <Fragment key={"addon_" + addon}>
                                    <Grid item xs={6}>
                                        <FormControlLabel label={prod.name} control={<Checkbox checked={!!addonInUse} onChange={(ev) => {
                                            enableDisableAddon(addon);
                                        }} />} />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Select native value={addonInUse ? addonInUse.productVariantId : ""} onChange={(ev) => setAddonVariant(addon, ev.target.value as string)}>
                                            {prod.variants.map((variant, idx) => {
                                                return <option key={variant.Id + "_" + idx} value={variant.Id}>{variant.Name}</option>
                                            })}
                                        </Select>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Select native value={addonInUse ? addonInUse.productVariantPriceGroupId : ""}
                                            onChange={(ev) => setAddonPriceGroup(addon, ev.target.value as string)}>
                                            {variant && variant.priceGroups.map((pg, idx) => {
                                                return <option key={pg.id} value={pg.id}>{pg.Name + " (" + pg.price + "kr)"}</option>
                                            })}
                                        </Select>
                                    </Grid>
                                </Fragment>;
                            }

                        })
                    }
                </Grid>

                <Grid item xs={12} style={{ borderTop: "1px dashed", background: "#eeeeee" }}>Rabattkoder</Grid>
                <Grid item xs={6}>Rabattkod</Grid>
                <Grid item xs={6}><TextField value={code ?? ""} onChange={(ev) => {
                    setCode(ev.target.value);
                }} />
                    <Button onClick={handleAddDiscountCode}>Lägg till</Button>
                </Grid>

                {isRaceProduct && <>
                    <Grid item xs={12} style={{ borderTop: "1px dashed", background: "#eeeeee" }}>Loppspecifika fält</Grid>
                    <Grid item xs={12}>
                        <AddEntryCustomFields
                            raceProduct={order.items[0].product}
                            variant={order.items[0].productVariantId}
                            entry={order.items[0].entry}
                            updateEntry={(entry) => {
                                order.items[0].entry = entry;
                                setOrder({ ...order });
                            }} />
                    </Grid>
                </>}

                <Grid item xs={12} style={{ borderTop: "1px dashed", background: "#eeeeee" }}>Summering</Grid>
                <Grid item xs={6}>Pris:</Grid>
                <Grid item xs={6}>{order.totalSum}kr</Grid>
                {order && order.discountCodes && order.discountCodes.map((code) => {
                    return <><Grid item xs={6}>Kod: {code.description}</Grid>
                        <Grid item xs={6}>-{code.value}kr</Grid></>
                })}
                {order && order.items && order.items.length === 1 && order.items[0].code &&
                    <Fragment>
                        <Grid item xs={6}>Anmälningskod</Grid>
                        <Grid item xs={6}>
                            {order.items[0].code}
                            <Button
                                onClick={handleRemoveOrderItemDiscountCode}
                            >
                                Ta bort
                            </Button>
                        </Grid>
                    </Fragment>
                }
                <Grid item xs={6}>Ombokningsvärde:</Grid>
                <Grid item xs={6}>-{rebookValue}kr</Grid>
                <Grid item xs={6}>Att betala:</Grid>
                <Grid item xs={6}>{toPay()}kr</Grid>
                <Grid item xs={6}>Skillnad regleras med:</Grid>
                <Grid item xs={6}>
                    <FormControlLabel label="Beloppsreglering" control={<Checkbox disabled={order.totalSum == rebookValue} checked={payWith === "beloppsreglering"} onChange={(ev) => { setPayWith("beloppsreglering") }} />} />
                    <FormControlLabel label="Skicka betallänk" control={<Checkbox disabled={order.totalSum <= rebookValue} checked={payWith === "link"} onChange={(ev) => { setPayWith("link") }} />} />
                    <FormControlLabel label="Återbetalning" control={<Checkbox disabled={order.totalSum >= rebookValue} checked={payWith === "repay"} onChange={(ev) => { setPayWith("repay") }} />} />
                    <FormControlLabel label="Boka mot konto" control={<Checkbox disabled={order.totalSum - rebookValue - sumDiscounts() == 0} checked={payWith === "account"} onChange={(ev) => { setPayWith("account") }} />} />
                    <FormControlLabel label="Kortbetalning/Swish" control={<Checkbox disabled={order.totalSum <= rebookValue} checked={payWith === "card_swish"} onChange={(ev) => { setPayWith("card_swish") }} />} />
                </Grid>
                {payWith === "account" && <>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <InputLabel shrink>Konto</InputLabel>
                            <SelectPointOfSale onChange={setPointOfSale} />
                        </FormControl>

                    </Grid>
                    <Grid item xs={6}>
                        <TextField label="Kommentar"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            value={paymentComment ?? ""}
                            onChange={(ev) => {
                                setPaymentComment(ev.target.value);
                            }}
                        />
                    </Grid>
                </>}
                <Grid item xs={12}>
                    <FormControlLabel label="Skicka orderbekräftelse till kund" control={<Checkbox checked={sendOrderEmail} onChange={(ev) => {
                        setSendOrderEmail(ev.target.checked);
                        onSendOrderEmailChanged(ev.target.checked);
                    }} />} />
                </Grid>
            </Grid>
        );
    };

    return render();
};

export default withAlert(ManageOrderRebookItem);
