import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, LinearProgress, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { OrderItem } from "../../../model/OrderItem";
import withAlert, { IAlertContext } from "../../Common/dialog/Alert";
import { ApiBackend } from "../../../providers/apibackend";
import SearchFilter, { Filters } from "../../Common/SearchFilter/SearchFilter";
import EnhancedTable from "../../Common/EnhancedTable/EnhancedTable";
import { ListEntry } from "../../../model/Entry";
import { IColumnDefinition } from "../../Common/EnhancedTable/models";
import { debounce } from "lodash";
import { EntryStatus } from "../../../model/CommonTypes";
import { TransferEnervitRequest } from "../../../providers/models";

interface Props {
    sourceOrderId: string;
    sourceItemId: string;
    onAbort: () => void;
    onTransferred: () => void;
}

interface ISearchFilter {
    firstName?: string;
    lastName?: string;
    email?: string;
    phone?: string;
    vasaId?: string;
}

type ProductMetadata = {
    productId: string;
    productName: string;
    variantId: string;
    variantName: string;
    entryName: string;
    entryStatus: EntryStatus;
    vasaId: string;
}

const hasEntryIdMetadata = (item: OrderItem): boolean => {
    if (!item.metadata) {
        return false;
    }

    return !!item.metadata.find(x => x.key === "entryId");
};

const initSearchFilter = () => {
    return {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        vasaId: "",
    };
};

const ManageOrderTransferEnervit = ({ sourceOrderId, sourceItemId, onAbort, onTransferred, showAlert }: Props & IAlertContext) => {
    const [ready, setReady] = useState(false);
    const [searchFilter, setSearchFilter] = useState(initSearchFilter() as ISearchFilter);
    const [entries, setEntries] = useState(null as ListEntry[]);
    const [targetEntry, setTargetEntry] = useState(null as ListEntry);
    const [searching, setSearching] = useState(false);
    const [productMeta, setProductMeta] = useState<ProductMetadata>(null);
    const [alertMessage, setAlertMessage] = useState("");
    const [isSaving, setIsSaving] = useState(false);

    const backend = new ApiBackend();
    const maxRows = 100;

    useEffect(() => {
        const init = async () => {
            const sourceOrder = await backend.retreiveOrder(sourceOrderId);
            const sourceOrderItem = sourceOrder.items.find(x => x.Id === sourceItemId);

            if (sourceOrderItem.reverted) {
                setAlertMessage("Denna rad är redan ombokad");
                return;
            }

            if (!hasEntryIdMetadata(sourceOrderItem)) {
                setAlertMessage("Saknar koppling till anmälan");
                return;
            }

            const entry = await backend.getEntry(sourceOrderItem.metadata.find(x => x.key === "entryId").value);
            const person = await backend.getPerson(entry.personId);

            const product = await backend.getProduct(entry.productId);
            const variant = product.variants.find(v => v.Id === entry.variantId);
            setProductMeta({
                productId: product.id,
                productName: product.name,
                variantId: variant.Id,
                variantName: variant.Name,
                entryName: `${entry.firstName} ${entry.lastName}`,
                entryStatus: entry.status,
                vasaId: person.vasaId
            });


            setReady(true);
        };

        init();
    }, []);

    useEffect(() => {
        if (searchFilter && ready) {
            performSearch();
        }
    }, [searchFilter])

    const handleRequestSearchFilterChangeDebounced = useRef(debounce(setSearchFilter, 500));

    const handleRequestSearchFilterChange = (nextValue: ISearchFilter): void => {
        handleRequestSearchFilterChangeDebounced.current(nextValue);
    };

    const performSearch = async (): Promise<void> => {
        setSearching(true);

        const { firstName, lastName, email, phone, vasaId } = searchFilter;

        const entriesResponse = await backend.listEntries({
            productVariant: [productMeta.variantId],
            status: "ACTIVE",
            firstName: firstName,
            lastName: lastName,
            email: email,
            phone: phone,
            vasaId: vasaId,
            begin: 0,
            limit: maxRows
        });

        setEntries(entriesResponse?.entries);
        setSearching(false);
    };

    const onEntrySelect = async (nextValue: ListEntry) => {
        setTargetEntry(nextValue);
    };

    const onRequestTransfer = async () => {
        try {
            setIsSaving(true);

            const request: TransferEnervitRequest = {
                orderItemId: sourceItemId,
                targetEntryId: targetEntry.id,
                issuedBy: ApiBackend.getCurrentUserName()
            }

            const transferResult = await backend.transferEnervit(sourceOrderId, request);
            if (transferResult) {
                onTransferred();
            } else {
                showAlert("Fel", "Någonting gick fel när vi försökte flytta Enervit");
            }
        } catch (error) {
            showAlert("Fel", "Någonting gick fel när vi försökte flytta Enervit");
        } finally {
            setIsSaving(false);
        }
    };

    const columnDefinitions: IColumnDefinition<ListEntry>[] = [
        {
            renderCell: (row) => {
                return (
                    <Button
                        variant="text"
                        color="secondary"
                        onClick={() => {
                            onEntrySelect(row);
                        }}
                    >
                        Välj
                    </Button>
                );
            }
        },
        { propName: "lastName", label: "Efternamn" },
        { propName: "firstName", label: "Förnamn" },
        { propName: "vasaId", label: "VASA-ID" },
        { propName: "birthDay", label: "Födelsedatum" },
        { propName: "email", label: "E-post" },
        { propName: "phone", label: "Telefon" },
    ];

    const renderContent = () => {
        if (!ready) {
            return (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <LinearProgress color="secondary" />
                    </Grid>
                </Grid>
            );
        }

        if (alertMessage) {
            return (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Alert severity="error">{alertMessage}</Alert>
                    </Grid>
                </Grid>
            );
        }

        return (<>
            <Grid container spacing={2} style={{ marginBottom: 10 }}>
                <Grid item xs={12}>
                    <Typography variant="h6">
                        Flytta Enervit för {productMeta.productName} / {productMeta.variantName}
                    </Typography>
                    <Typography variant="body2">
                        Flytta från: {productMeta.entryName} ({productMeta.vasaId})
                    </Typography>
                </Grid>
            </Grid>

            {!targetEntry &&
                <Box>
                    <Grid container spacing={2} style={{ marginBottom: 10 }}>
                        <Grid item xs={12}>
                            <Typography variant="h6">
                                Sök anmälning att flytta till
                            </Typography>
                        </Grid>
                    </Grid>
                    <SearchFilter
                        id={"rebook-enervit-search-entries-filter"}
                        filters={{
                            "lastName": {
                                id: "filter-last-name",
                                type: "DebouncedText",
                                label: "Efternamn",
                                size: 4
                            },
                            "firstName": {
                                id: "filter-first-name",
                                type: "DebouncedText",
                                label: "Förnamn",
                                size: 4
                            },
                            "vasaId": {
                                id: "filter-vasaId",
                                type: "DebouncedText",
                                label: "VASA-ID",
                                size: 4
                            },
                            "email": {
                                id: "filter-email",
                                type: "DebouncedText",
                                label: "E-post",
                                size: 4
                            },
                            "phone": {
                                id: "filter-phone",
                                type: "DebouncedText",
                                label: "Telefon",
                                size: 4
                            },
                        }}
                        persist={false}
                        onChange={(filter: Filters<ISearchFilter>) => {
                            handleRequestSearchFilterChange(filter as unknown as ISearchFilter);
                        }}
                    />

                    <Grid container spacing={2}>
                        {searching &&
                            <Grid item xs={12}>
                                <LinearProgress color="secondary" />
                            </Grid>
                        }

                        <EnhancedTable<ListEntry>
                            columnDefinitions={columnDefinitions}
                            data={entries ?? []}
                            pageSize={10}
                            maxRows={maxRows}
                            paginationMode="client"
                            sortingMode="client"
                            loading={searching}
                            dense
                        />
                    </Grid>
                </Box>
            }

            {targetEntry &&
                <Box>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="body2">
                                Flytta till: {targetEntry.firstName} {targetEntry.lastName} ({targetEntry.vasaId})
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            }
        </>);
    };

    const render = () => {
        const abortDisabled = isSaving;
        const saveDisabled = isSaving || !targetEntry;

        return <Dialog
            disableEscapeKeyDown
            maxWidth="lg"
            fullWidth={true}
            aria-labelledby="transfer-enervit-dialog-title"
            open={true}>
            <DialogTitle id="transfer-enervit-dialog-title">
                Flytta Enervit
            </DialogTitle>
            <DialogContent dividers>
                {renderContent()}
            </DialogContent>
            <DialogActions>
                <Box style={{ display: "flex", width: "100%", height: "40px" }}>
                    {isSaving &&
                        <Box style={{ width: 200 }}>
                            <CircularProgress color="secondary" />
                        </Box>
                    }
                    <Box style={{ display: "flex", flex: 1, gap: 8, justifyContent: "flex-end" }}>
                        <Button
                            variant="contained"
                            disabled={saveDisabled}
                            onClick={() => {
                                onRequestTransfer();
                            }}>
                            Spara
                        </Button>
                        <Button
                            variant="contained"
                            disabled={abortDisabled}
                            onClick={() => {
                                onAbort();
                            }}>
                            Avbryt
                        </Button>
                    </Box>
                </Box>
            </DialogActions>
        </Dialog>;
    };

    return render();
};

export default withAlert(ManageOrderTransferEnervit);
