import { Box, Checkbox, Paper, Table, TableBody, TableCell, TableContainer, TablePagination, TableRow, Theme, createStyles, makeStyles } from "@material-ui/core";
import React, { useEffect, useRef } from "react";
import EnhancedTableHead from "./EnhancedTableHead";
import { ComparatorType, EnhancedTableProps, IColumnDefinition, PageSize, SortOrder } from "./models";
import { getCellStyle, getComparator, getCustomComparator, getRowsPerPageOptions, stableSort } from "./utils";
import EnhancedTableFooter from "./EnhancedTableFooter";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
        },
        paper: {
            width: "100%",
            marginBottom: theme.spacing(2),
        },
        table: {
            minWidth: 750,
        },
        visuallyHidden: {
            border: 0,
            clip: "rect(0 0 0 0)",
            height: 1,
            margin: -1,
            overflow: "hidden",
            padding: 0,
            position: "absolute",
            top: 20,
            width: 1,
        },
    }),
);

const EnhancedTable = <T extends unknown>(props: EnhancedTableProps<T>) => {
    const { data, pageSize, page, sortModel, columnDefinitions, dense, sortingMode, paginationMode, rowCount, maxRows, loading, sticky, selectable, showFooter, onRowClicked, onSelectChanged, onRequestSort, onSortModelChange, onRequestPageChange } = props;

    const classes = useStyles();
    const [sortOrder, setSortOrder] = React.useState<SortOrder>(sortModel?.sortOrder);
    const [sortBy, setSortBy] = React.useState<keyof T>(sortModel?.sortBy);
    const [currentPage, setCurrentPage] = React.useState(page ?? 0);
    const [rowsPerPage, setRowsPerPage] = React.useState(pageSize ?? 10 as PageSize);
    const [selected, setSelected] = React.useState<readonly T[]>([]);
    const [isLoading, setIsLoading] = React.useState(loading ?? false);

    const rowsPerPageOptions = getRowsPerPageOptions(maxRows);
    const didMount = useRef(false);

    useEffect(() => {
        didMount.current = true;

        // unmount
        return () => { didMount.current = false; };
    }, []);

    useEffect(() => {
        if (!didMount.current) {
            return;
        }

        setCurrentPage(page ?? 0);
    }, [page]);

    useEffect(() => {
        if (!didMount.current) {
            return;
        }

        if (paginationMode !== "server") {
            setCurrentPage(0);
        }

        setSelected([]);
    }, [data]);

    useEffect(() => {
        if (!didMount.current) {
            return;
        }

        setIsLoading(loading);
    }, [loading]);

    useEffect(() => {
        if (!didMount.current) {
            return;
        }

        if (!!onSelectChanged) {
            onSelectChanged(selected);
        }
    }, [selected]);

    useEffect(() => {
        if (!didMount.current) {
            return;
        }

        setSelected([]);
    }, [currentPage]);

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof T) => {
        let nextProp: keyof T | undefined = property;
        let nextSortOrder: SortOrder | undefined;
        if (sortBy === property) {
            if (sortOrder === "desc") {
                nextProp = undefined;
                nextSortOrder = undefined;
            } else {
                nextSortOrder = sortOrder === "asc" ? "desc" : "asc";
            }
        } else {
            nextSortOrder = "asc";
        }

        setSortOrder(nextSortOrder);
        setSortBy(nextProp);

        if (sortingMode === "server" && onRequestSort) {
            onRequestSort({ sortBy: nextProp, sortOrder: nextSortOrder });
        } else if (onSortModelChange) {
            onSortModelChange({ sortBy: nextProp, sortOrder: nextSortOrder });
        }
    };

    const handleRowClick = (event: React.MouseEvent<unknown>, row: T) => {
        if (selectable) {
            handleRowSelectChange(row);
        }

        if (onRowClicked) {
            onRowClicked(row);
        }
    };

    const handleRowSelectChange = (row: T) => {
        const selectedIndex = selected.indexOf(row);
        let newSelected: readonly T[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, row);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    }

    const handleCellClick = (event: React.MouseEvent<unknown>, row: T, def: IColumnDefinition<T>) => {
        if (def.onCellClick) {
            def.onCellClick(row, def);
        }
    };

    const handleRequestSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const preparedData = getViewData();
            setSelected(preparedData);

            return;
        }

        setSelected([]);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setCurrentPage(newPage);

        if (paginationMode === "server" && onRequestPageChange) {
            onRequestPageChange(newPage, pageSize);
        }
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        const nextPageSize = parseInt(event.target.value, 10) as PageSize;
        setRowsPerPage(nextPageSize);
        setCurrentPage(0);

        if (paginationMode === "server" && onRequestPageChange) {
            onRequestPageChange(0, nextPageSize);
        }
    };

    const createCells = (row: T) => {
        return columnDefinitions.map((def, idx) => {
            const cursor = !!def.onCellClick ? "pointer" : "";
            let style = getCellStyle(idx, sticky, columnDefinitions);
            style.cursor = cursor;

            if (def.renderCell) {
                return <TableCell key={idx} onClick={(event) => handleCellClick(event, row as T, def)} align={def.align ?? "left"} style={style}>
                    <Box component="span" style={{ whiteSpace: "nowrap" }}>
                        {def.renderCell(row)}
                    </Box>
                </TableCell>;
            }

            const value = def.valueFormatter ? def.valueFormatter(row) : row[def.propName];
            return <TableCell key={idx} onClick={(event) => handleCellClick(event, row as T, def)} align={def.align ?? "left"} style={style}>
                <Box component="span" style={{ whiteSpace: "nowrap" }}>
                    {value}
                </Box>
            </TableCell>;
        });
    };

    const getViewData = () => {
        let preparedData = data;
        if (sortingMode !== "server") {
            const def = columnDefinitions.find((x) => x.propName === sortBy);
            let comparator: ComparatorType<T>;
            if (def?.sortComparator) {
                comparator = getCustomComparator(sortOrder, def.sortComparator);
            } else {
                comparator = getComparator(sortOrder, sortBy, def?.sortValueGetter) as any;
            }
            preparedData = stableSort(preparedData, comparator);
        }

        if (paginationMode !== "server") {
            preparedData = preparedData.slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage);
        }

        return preparedData;
    };

    const isSelected = (row: T) => selected.indexOf(row) !== -1;

    const render = () => {
        const preparedData = getViewData();

        const tableStyle: React.CSSProperties = {
            borderSpacing: 0,
            borderCollapse: "separate"
        };
        const paperStyle: React.CSSProperties = isLoading ? { opacity: 0.5, pointerEvents: "none" } : null;
        return (
            <div className={classes.root}>
                <Paper className={classes.paper} style={paperStyle}>
                    <TableContainer>
                        <Table
                            className={classes.table}
                            style={tableStyle}
                            aria-labelledby="tableTitle"
                            size={dense ? "small" : "medium"}
                            aria-label="enhanced table"
                        >
                            <EnhancedTableHead<T>
                                classes={classes}
                                columnDefinitions={columnDefinitions}
                                sortOrder={sortOrder}
                                sortBy={sortBy}
                                sticky={sticky}
                                selectable={selectable}
                                numSelected={selected.length}
                                selectableCount={preparedData.length}
                                onRequestSort={handleRequestSort}
                                onSelectAllClick={handleRequestSelectAll}
                            />
                            <TableBody>
                                {preparedData
                                    .map((row, rowIdx) => {
                                        const isItemSelected = isSelected(row);
                                        return (
                                            <TableRow
                                                hover
                                                onClick={(event) => handleRowClick(event, row as T)}
                                                role="checkbox"
                                                tabIndex={-1}
                                                key={rowIdx}
                                            >
                                                <>
                                                    {selectable &&
                                                        <TableCell padding="checkbox">
                                                            <Checkbox
                                                                color="primary"
                                                                checked={isItemSelected}
                                                            />
                                                        </TableCell>
                                                    }

                                                    {createCells(row as T)}
                                                </>
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                            {showFooter &&
                                <EnhancedTableFooter<T>
                                    columnDefinitions={columnDefinitions}
                                    data={preparedData}
                                    selectable={selectable}
                                />
                            }
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={rowsPerPageOptions}
                        component="div"
                        count={rowCount ?? data.length}
                        rowsPerPage={rowsPerPage}
                        page={currentPage}
                        labelDisplayedRows={({ from, to, count }) =>
                            maxRows ? `${from}-${to === -1 ? count : to} av ${count} (max: ${maxRows})` : `${from}-${to === -1 ? count : to} av ${count}`
                        }
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            </div>
        );
    };

    return render();
}

export default EnhancedTable;
