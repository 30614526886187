import React, { useEffect, useRef, useState } from "react";
import { Box, Button, CardContent, CardHeader, Grid, LinearProgress, Typography, WithStyles, createStyles, withStyles } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";
import withAlert, { IAlertContext } from "../../Common/dialog/Alert";
import { IColumnDefinition } from "../../Common/EnhancedTable/models";
import EnhancedTable from "../../Common/EnhancedTable/EnhancedTable";
import { Person, PersonCounter } from "../../../model/Person";

interface IProps {
    isPersonDirty: boolean;
    person: Person;
}

const defaultBoxStyle = {
    display: "flex",
    flexDirection: "row",
};

const PersonCounters = ({ classes, isPersonDirty, person, showAlert }: WithStyles & IAlertContext & IProps) => {
    const [loading, setLoading] = useState(false);
    const [counters, setCounters] = useState(null as PersonCounter[]);


    useEffect(() => {
        setCounters(person.counters);
        setLoading(false);
    },[]);

    const columnDefinitions: IColumnDefinition<PersonCounter>[] = [
        {
            propName: "name",
            label: "Räknare",
            renderCell: (row) => {
                return <Typography style={{ textDecoration: 'underline', cursor: 'pointer' }}
                    onClick={() => {}}>{row.name}
                </Typography>
            }
        },
        {
            propName: "count",
            label: "Antal",
            valueFormatter: (row) => { return row.count ? row.count + "st" : ""}
        }
    ];


    const render = (): JSX.Element => {
        return (
            <>
                <Grid container className={classes.root} spacing={2} style={{ width: '100%' }}>
                    <Grid item xs={12}>
                        <CardHeader
                            className={classes.cardHeader}
                            title={
                                <Typography variant="h5" style={{ display: 'inline' }}>
                                    Räknare
                                </Typography>
                            }
                            action={
                                <Box {...defaultBoxStyle} style={{ gap: "16px", marginLeft: "auto", marginTop: 8, marginRight: 8 }}>
                                    <Button color="primary" variant="text"
                                        onClick={async () => {
                                            if (isPersonDirty) {
                                                showAlert("Personen har osparade ändringar!", "Spara för att lägga till en räknare.");
                                                return;
                                            }
                                        }}>Lägg till räknare
                                    </Button>
                                </Box>
                            }
                        />
                        <CardContent>
                            {loading &&
                                <LinearProgress color="secondary" />
                            }
                            <Grid container className={classes.root} spacing={2}>
                                <Grid item xs={12}>
                                    <EnhancedTable<PersonCounter>
                                        columnDefinitions={columnDefinitions}
                                        data={counters ?? []}
                                        pageSize={50}
                                        page={0}
                                        rowCount={counters ? counters.length : 0}
                                        loading={loading}
                                        dense
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Grid>
                </Grid>
            </>);
    };

    return render();
};

const useStyles = ({ palette, spacing }: Theme) => createStyles({
    cardHeader: {
        background: palette.secondary.main,
        color: palette.secondary.contrastText,
        padding: 3
    },
    photo: {
        height: '30px',
        verticalAlign: 'middle',
        borderRadius: '10px'
    },
    root: {

    },
    form: {
        '& > *': {
            margin: spacing(1),
            width: '25ch',
        },
        '& label.Mui-focused': {
            color: palette.secondary.main,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: palette.secondary.main,
        },
    }
});

export default withAlert(withStyles(useStyles)(PersonCounters));
