import React, { Fragment } from 'react';
import Grid, { GridSize } from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { OrderItem } from '../../model/OrderItem';
import TransformIcon from '@material-ui/icons/Transform';
import { Entry, EntryAddonProduct } from '../../model/Entry';
import EditIcon from '@material-ui/icons/Edit';
import EditEntryDialog from '../Entries/Entry/EditEntryDialog';
import VasaloppetTooltip from '../Common/VasaloppetTooltip';
import { IconButton } from '@material-ui/core';

class Props {
    item: OrderItem;
    rowStyle: any;
    editMode?: boolean;
    onConvertEntryAddonToOwnItem: (toRemoveEntryAddon: EntryAddonProduct, newOrderItem: OrderItem) => void;
    onChange: () => void;
}
class State {
    editEntry: string;
}

class OrderRowEntry extends React.Component<Props, State> {
    formatSocialSecurityNumber(dateOfBirthYear: number, dateOfBirthMonth: number, dateOfBirthDay: number, socialSecurityNumber: string): string {
        if (!dateOfBirthYear || !dateOfBirthMonth || !dateOfBirthDay) {
            return undefined;
        }
        let ret = dateOfBirthYear + "" + (dateOfBirthMonth + "").padStart(2, '0') + "" + (dateOfBirthDay + "").padStart(2, '0');
        if (!socialSecurityNumber)
            return ret;

        return ret + "-" + socialSecurityNumber;
    }

    convertAddonToItem = (addon: EntryAddonProduct) => {
        var oi = new OrderItem();
        oi.itemPrice = addon.productVariantPrice;
        oi.productId = addon.productId;
        oi.numberOfProducts = 1;
        oi.productName = addon.productName;
        oi.productVariantId = addon.productVariantId;
        oi.variantName = addon.productVariantName;
        oi.productVariantPriceGroupId = addon.productVariantPriceGroupId;
        const entry = this.props.item.entry;
        oi.metadata = [{
            key: "entryId",
            value: entry.id
        },
        {
            key: "socialSecurityNumber",
            value: this.formatSocialSecurityNumber(entry.dateOfBirthYear, entry.dateOfBirthMonth, entry.dateOfBirthDay, entry.socialSecurityNumber)
        }, {
            key: "personId",
            value: entry.personId
        },
        {
            key: "firstName",
            value: entry.firstName
        },
        {
            key: "lastName",
            value: entry.lastName
        },
        {
            key: "address",
            value: entry.address
        },
        {
            key: "postalCode",
            value: entry.postalCode
        },
        {
            key: "city",
            value: entry.city
        },
        {
            key: "country",
            value: entry.country
        },
        {
            key: "nationality",
            value: entry.nationality
        },
        {
            key: "email",
            value: entry.email
        },
        {
            key: "phone",
            value: entry.phone
        }
        ];
        this.props.onConvertEntryAddonToOwnItem(addon, oi);
    }

    isConvertibleToOwnRow = (addon: EntryAddonProduct) => {
        return addon.productName?.indexOf("Folksam") >= 0 || addon.productName?.indexOf("Enervit") >= 0;
    }

    render() {
        const gridWidths: GridSize[] = this.props.editMode ? [1, 8, 1, 1] : [2, 6, 2, 2];

        const style = { ...this.props.rowStyle, ...{ paddingTop: 0, marginTop: -5 } };
        return <Fragment>
            {this.props.editMode &&
                <Grid style={style} item xs={1}></Grid>
            }
            <Grid style={style} item xs={gridWidths[0]}></Grid>
            <Grid item xs={gridWidths[1]} style={style}>
                <Typography variant="body2" style={{ margin: 0 }}>
                    <IconButton size="small"
                        onClick={() => {
                            this.setState({ editEntry: this.props.item.entry.id });
                        }}
                    >
                        <EditIcon style={{ fontSize: 20 }} />
                    </IconButton>
                    <b>Deltagare: </b>{this.props.item.entry.firstName + " " + this.props.item.entry.lastName}
                </Typography>
            </Grid>
            <Grid item xs={gridWidths[2]} style={style}><Typography variant="body2" style={{ margin: 0 }}></Typography></Grid>
            <Grid item xs={gridWidths[3]} style={style}><Typography variant="body2" style={{ margin: 0 }}></Typography></Grid>
            {this.props.item.entry && this.props.item.entry.addOns && this.props.item.entry.addOns.map((addon, idx) => {
                return <Fragment key={idx}>
                    {this.props.editMode &&
                        <Grid style={style} item xs={1}></Grid>
                    }
                    <Grid item xs={gridWidths[0]} style={style}><Typography variant="body2" style={{ margin: 0 }}><i>1 st</i></Typography></Grid>
                    <Grid item xs={gridWidths[1]} style={style}>
                        <Typography variant="body2" style={{ margin: 0 }}>
                            {this.isConvertibleToOwnRow(addon) && (
                                <IconButton size="small"
                                    onClick={() => {
                                        this.convertAddonToItem(addon)
                                    }}
                                >
                                    <VasaloppetTooltip title="Konvertera till egen orderrad">
                                        <TransformIcon />
                                    </VasaloppetTooltip>
                                </IconButton>
                            )}
                            <i>{addon.productName + " (" + addon.productVariantName + ")"}</i>
                        </Typography>
                    </Grid>
                    <Grid item xs={gridWidths[2]} style={style}><Typography variant="body2" style={{ margin: 0 }}><i>{addon.productVariantPrice} kr</i></Typography></Grid>
                    <Grid item xs={gridWidths[3]} style={style}><Typography variant="body2" style={{ margin: 0 }}><i>{addon.productVariantPrice} kr</i></Typography></Grid>
                </Fragment>;
            })}
            {this.state && this.state.editEntry &&
                <EditEntryDialog
                    entryId={this.state.editEntry}
                    onAbortEdit={() => {
                        this.setState({ editEntry: null });
                        this.props.onChange();
                    }}
                    onSave={(nextValue: Entry) => {
                        this.setState({ editEntry: null });
                        this.props.onChange();
                    }}
                />
            }
        </Fragment>;

    }
}

export default OrderRowEntry;
